import moment from "moment";

import { PartnersList, TargetAudience } from "../data/common";
import { PublicationType, ColumnFieldsSP } from "../data/DataSP";
import {
  MultiSelectDropdown, FileUploadCell, YearPicker,
  RenderMultiSelectResult, RenderFile,
  CustomSortMultiSelect, 
  CustomFilterMultiSelect, CustomFilterComponent,
  RenderCustomLink, RenderDOI, CustomInput
} from "../CustomColumns";
import Validation from "../DataValidation";

const ColumnsSP = [
  { 
    title: "Id", 
    field: ColumnFieldsSP.id,
    hidden: true 
  },
  { 
    title: "Date", 
    field: ColumnFieldsSP.date, 
    editComponent: YearPicker.bind(this),
    render: rowData => moment(rowData[ColumnFieldsSP.date]).format("YYYY"),
    validate: Validation.ValidateDate.bind(this, ColumnFieldsSP.date),
    cellStyle: {
      width: "6%"
    },
  },
  { 
    title: "Partners", 
    field: ColumnFieldsSP.partners, 
    lookup: PartnersList,
    editComponent: MultiSelectDropdown.bind(this, PartnersList),
    render: RenderMultiSelectResult.bind(this, ColumnFieldsSP.partners, PartnersList),
    customSort: CustomSortMultiSelect.bind(this, ColumnFieldsSP.partners, PartnersList),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, ColumnFieldsSP.partners),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsSP.partners),
    cellStyle: {
      width: "8%"
    },
  },
  { 
    title: "Type of publication", 
    field: ColumnFieldsSP.publicationType, 
    lookup: PublicationType,
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateSelect.bind(this, ColumnFieldsSP.publicationType),
    cellStyle: {
      width: "10%"
    },
  },
  {
    title: "Authors",
    field: ColumnFieldsSP.authors,
    validate: Validation.ValidateAuthors.bind(this, ColumnFieldsSP.authors),
    cellStyle: {
      width: "14%"
    },
  },
  {
    title: "Publication Title",
    field: ColumnFieldsSP.publicationTitle,
    validate: Validation.ValidateCapitalizedInput.bind(this, ColumnFieldsSP.publicationTitle),
    cellStyle: {
      width: "10%"
    },
  },
  {
    title: "Journal / Conference Name",
    field: ColumnFieldsSP.journalName,
    editComponent: CustomInput.bind(this),
    validate: Validation.ValidateJournalName.bind(this, ColumnFieldsSP.journalName),
    cellStyle: {
      width: "10%",
      minWidth: 140
    }
  },
  {
    title: "Publication DOI",
    field: ColumnFieldsSP.publicationDOI,
    editComponent: CustomInput.bind(this),
    render: RenderDOI.bind(this, ColumnFieldsSP.publicationDOI),
    validate: Validation.ValidateDOI.bind(this, ColumnFieldsSP.publicationDOI),
    cellStyle: {
      width: "8%"
    },
  },
  {
    title: "Zenodo / Repository Link",
    field: ColumnFieldsSP.zenodoLink,
    render: RenderCustomLink.bind(this, ColumnFieldsSP.zenodoLink),
    validate: Validation.ValidateLink.bind(this, ColumnFieldsSP.zenodoLink),
    cellStyle: {
      width: "10%"
    },
  },
  { 
    title: "Target Audience", 
    field: ColumnFieldsSP.targetAudience, 
    lookup: TargetAudience,
    editComponent: MultiSelectDropdown.bind(this, TargetAudience),
    render: RenderMultiSelectResult.bind(this, ColumnFieldsSP.targetAudience, TargetAudience),
    customSort: CustomSortMultiSelect.bind(this, ColumnFieldsSP.targetAudience, TargetAudience),
    customFilterAndSearch: CustomFilterMultiSelect.bind(this, ColumnFieldsSP.targetAudience),
    filterComponent: CustomFilterComponent.bind(this),
    validate: Validation.ValidateMultipleSelect.bind(this, ColumnFieldsSP.targetAudience),
    cellStyle: {
      width: "10%"
    },
  },
  { 
    title: "Photo", 
    field: ColumnFieldsSP.photo,
    editComponent: FileUploadCell.bind(this),
    render: RenderFile.bind(this),
    validate: Validation.ValidateFile.bind(this, ColumnFieldsSP.photo),
    filtering: false,
    cellStyle: {
      width: "14%"
    },
  }
];

export default ColumnsSP;