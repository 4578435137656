import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

import { ColumnFieldsSP } from '../../pages/reports/data/DataSP';
import { DefaultJournalName, NotApplicable } from '../../utils/utils';

function MTextField({ forwardedRef, ...props }) {
  const {
    columnDef,
    rowData,
    onRowDataChange,
    errorState,
    autoFocus,
    onBulkEditRowChanged,
    scrollWidth,
    value,
    onChange,
    ...remProps
  } = props;
  const [disabled, setDisabled] = useState(false);
  const [placeholder, setPlaceholder] = useState(columnDef.editPlaceholder || columnDef.title);

  useEffect(() => {
    const checkIfThesis = (data) => {
      const publicationType = data[ColumnFieldsSP.publicationType];
      const isThesis = publicationType === "masterThesis" || publicationType === "phdThesis";

      if (isThesis) {
        let newValue = columnDef.title;
        if (columnDef.title === "Journal / Conference Name") {
          newValue = DefaultJournalName;
        } else if (columnDef.title === "Publication DOI") {
          newValue = NotApplicable;
        }

        setPlaceholder(newValue);
        setDisabled(true);
      } else {
        setPlaceholder(columnDef.editPlaceholder || columnDef.title);
        setDisabled(false);
      }
    }

    checkIfThesis(rowData);
  }, [columnDef, rowData]);

  return (
    <TextField
      {...remProps}
      ref={forwardedRef}
      fullWidth
      style={columnDef.type === 'numeric' ? { float: 'right' } : {}}
      type={columnDef.type === 'numeric' ? 'number' : 'text'}
      placeholder={placeholder}
      value={value === undefined ? '' : value}
      disabled={disabled}
      onChange={(event) =>
        onChange(
          columnDef.type === 'numeric'
            ? event.target.valueAsNumber
            : event.target.value
        )
      }
      InputProps={{
        style: {
          minWidth: 50,
          fontSize: 13
        }
      }}
      inputProps={{
        'aria-label': columnDef.title
      }}
    />
  );
}

export default React.forwardRef(function MTextFieldRef(props, ref) {
  return <MTextField {...props} forwardedRef={ref} />;
});