const getNews = {
  getNews() {
    return fetch(`${window.$apiUrl}/news-posts?_sort=published_at:DESC&_limit=-1`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonReponse) => {
        return jsonReponse.map((news) => {
          return {
            id: news.id,
            title: news.Title,
            content: news.Content,
            image: news.Images,
            video: news.Video,
            file: news.Files,
            author: news.Author,
            link: news.Link,
            published: news.published_at,
            type: "news"
          };
        });
      });
  },
};

export default getNews;
