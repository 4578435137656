import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import DropdownItem from "./DropdownItem"

import "./Dropdown.css";

export default class ProjectDropdown extends Component {
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/project")) {
      return true;
    }

    return false;
  };

  render() {
    return (
      <div className="dropdown">
        <Link
          className="dropbtn main-link"
          variant="inherit"
          color="inherit"
          underline="none"
          component={NavLink}
          activeClassName="selected"
          isActive={this.matchResourcesPages.bind(this)}
          exact={true}
          to="#"
        >
          Project
        </Link>

        <div className="dropdown-content">
          <div className="dropdown-placeholder" />

          <div className="dropdown-links">
            <DropdownItem link="/project/about-the-project">
              About the project
            </DropdownItem>

            <DropdownItem link="/project/toolbox">
              Toolbox
            </DropdownItem>

            <DropdownItem link="/project/business-cases">
              Business Cases
            </DropdownItem>

            <DropdownItem link="/project/advisory-board">
              Advisory Board
            </DropdownItem>
          </div>
        </div>
      </div>
    );
  }
}
