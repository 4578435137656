import React from "react";
// import { Grid } from "@material-ui/core";

// import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

import YouTubeIcon from "@material-ui/icons/YouTube";

function ContactItem(props) {
  return (
    <div className="footer-contact">
      <div className="footer-contact-icon">{props.icon}</div>
      <div className="footer-contact-text">{props.children}</div>
    </div>
  );
}

export default function FooterContact() {
  let iconColor = "#00FFFF";

  return (
    <div>
      <div className="footer-email">
        <ContactItem icon={<EmailIcon htmlColor={iconColor} className="footer-email-icon" />}>
          <div className="footer-email-text">Dumitru Roman - Project coordinator</div>
          <a href="mailto:Dumitru.Roman@sintef.no">Dumitru.Roman@sintef.no</a>
        </ContactItem>
      </div>

      <div className="footer-email">
        <a 
          href="https://www.youtube.com/channel/UCVUkE2oBHCgTGB3QypNOPYw"
          target="_blank"
          rel="noreferrer"
        >
          <ContactItem icon={<YouTubeIcon htmlColor={iconColor} />}>
            YouTube - DataCloud Project
          </ContactItem>
        </a>
      </div>

      <div className="footer-social-media" target="_blank" rel="noreferrer">
        <a
          href="https://twitter.com/DataCloud2020"
          target="_blank"
          rel="noreferrer"
        >
          <ContactItem icon={<TwitterIcon htmlColor={iconColor} />}>
            twitter.com/DataCloud2020
          </ContactItem>
        </a>

        {/* <a href="https://www.linkedin.com/">
          <ContactItem icon={<LinkedInIcon htmlColor={iconColor} />}>
            linkedin.com/
          </ContactItem>
        </a> */}
      </div>
    </div>
  );
}
