import React, {Component} from "react";
import {Link} from "@material-ui/core";
import {NavLink} from "react-router-dom";

import BackButton from "../backbutton/BackButton";

import "./Header.css";

export default class Header extends Component {
  //Removes id from url
  removeLastUrlDirectory() {
    var path = window.location.pathname.split("/");
    path.pop();
    return path.join("/");
  }

  render() {
    return (
      <div className="header-section-individual">
        <div className="header-backdrop-individual" />
        <div
          className="header-background-individual"
          style={{backgroundImage: `url("${this.props.img}")`}}
        />
        <div className="header-container">
          <div className="header-title"> {this.props.title}</div>
          {this.props.type === "events" ? (
            <div className="header-event-info">
              <div><b>Location:</b> {this.props.location}</div>
              <div className="date-time">
                <div>
                  {this.props.date} | {this.props.time}
                </div>
              </div>
            </div>
          ) : null}
          <div className="header-back-button">
            <Link
              underline="none"
              component={NavLink}
              to={this.removeLastUrlDirectory()}
            >
              <BackButton>Back to {this.props.type}</BackButton>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
