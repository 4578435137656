import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class JOT extends Component {
  render() {
    return (
      <div>
        <MetaTags title="JOT Internet Media | Consortium | DataCloud Project" />
        <PartnersHeader
          title="JOT Internet Media"
          color="#9A1A7D"
        ></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              JOT Internet Media is one of the world's leading platforms for
              high quality audience acquisition for global digital players
              created in 2004. <br />
              <br />
              JOT is one of the three largest worldwide players in international
              brokering of high volumes of multichannel Internet traffic, which
              includes search engine queries, social networks media and
              advertising space. The company operation reaches large technology
              partners such as Google, Bing/Yahoo, Ask.com, Web.de and CBS. JOT
              manages campaigns in 74 countries with more than 3200 accounts in
              17 languages, counting with around 250 million impressions, 8.7
              million clicks, and over 3,800 million keywords per day.
            </div>
            <div className="partner-button">
              <a
                href="https://www.jot-im.com/"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              JOT main role is focused on the coordination of end user
              requirement definition as well as market needs and data
              management. In addition, the company will host a use case related
              to the application of DataCloud solutions to the generation of a
              prediction service for mobile marketing campaigns, finally JOT
              will coordinate the business strategy definition at project and
              partner level together with an active participation in
              dissemination activities.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
