import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavHashLink as NavLink } from "react-router-hash-link";

import HomeButton from "../../components/homebutton/HomeButton";
import { scrollWithOffset } from "../../utils/utils";

export default class CarouselPanel extends Component {
	render() {
		return (
			<aside className="panel">
        <div className="panel-img">
          <img src={this.props.data.img} alt={this.props.data.header}/>
        </div>

        <div className="panel-text">
          <h2 className="panel-header">{this.props.data.header}</h2>
          <p className="panel-info">{this.props.data.body}</p>
          <Link 
            variant="inherit"
            color="inherit"
            underline="none"
            component={NavLink}
            scroll={(el) => scrollWithOffset(el)}
            to={this.props.data.link}
          >
            <HomeButton>
              {this.props.data.btnText}
            </HomeButton>
          </Link>
        </div>
			</aside>
		);
	}
}