import React, { Component } from "react";

import ObjectivesItem from "./ObjectivesItem";

import objOneImg from "../../../assets/images/proj_obj_1.jpg";
import objTwoImg from "../../../assets/images/proj_obj_2.jpg";
import objThreeImg from "../../../assets/images/proj_obj_3.jpg";
import objFourImg from "../../../assets/images/proj_obj_4.jpg";
import objFiveImg from "../../../assets/images/proj_obj_5.jpg";
import objSixImg from "../../../assets/images/proj_obj_6.jpg";

import "./Objectives.css";

export default class Objectives extends Component {
  constructor(props) {
    super(props);
    this.state = { right: true };

    this.checkScreenSize = this.checkScreenSize.bind(this);
  }

  checkScreenSize = () => {
    let screenSize = window.innerWidth;

    if (screenSize <= 960) {
      this.setState({ right: false });
    } else {
      this.setState({ right: true });
    }
  };

  componentDidMount() {
    this.checkScreenSize();

    window.addEventListener("resize", this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  }

  render() {
    return (
      <div className="objectives-project">
        <div className="objectives-project-wrapper">
          <h2 className="objectives-title">Objectives</h2>

          <ObjectivesItem
            alignRight={false}
            imgSrc={objOneImg}
            imgAlt="Pipelines discovery"
            title="Big Data pipelines discovery"
          >
            To develop techniques for discovering Big Data pipelines from
            various data sources, featuring the use of AI-based and process
            mining algorithms using data-driven discovery approaches for
            learning their structure.
          </ObjectivesItem>

          <ObjectivesItem
            alignRight={this.state.right}
            imgSrc={objTwoImg}
            imgAlt="Pipelines definition"
            title="Big Data pipelines definition"
          >
            To develop a domain-specific language (DSL) for Big Data pipelines
            featuring an abstraction level suitable for pure data processing,
            which realizes pipeline specifications using instances of a
            predefined set of scalable and composable container templates
            (corresponding to step types in pipelines).
          </ObjectivesItem>

          <ObjectivesItem
            alignRight={false}
            imgSrc={objThreeImg}
            imgAlt="Pipelines simulation"
            title="Big Data pipelines simulation"
          >
            To develop a novel Big Data pipeline simulation framework for
            determining the “best” deployment scenario by evaluating the
            performance of individual steps in a sandboxed environment and
            varying different aspects of input data and step parameters.
          </ObjectivesItem>

          <ObjectivesItem
            alignRight={this.state.right}
            imgSrc={objFourImg}
            imgAlt="Blockchain-based resources"
            title="Blockchain-based resources provisioning for Big Data pipelines"
          >
            To develop a blockchain-based resource marketplace for securely
            provisioning, for any given Big Data pipeline, a set of (trusted and
            untrusted) resources (Cloud, Edge, Fog), ensuring privacy and
            security of data and pipelines executions.
          </ObjectivesItem>

          <ObjectivesItem
            alignRight={false}
            imgSrc={objFiveImg}
            imgAlt="Flexible and automated deployment"
            title="Flexible and automated deployment of Big Data pipelines"
          >
            To develop a deployment framework for data pipelines specifications,
            featuring secure, adaptable, elastic, scalable, and resilient
            resource.
          </ObjectivesItem>

          <ObjectivesItem
            alignRight={this.state.right}
            imgSrc={objSixImg}
            imgAlt="Fog/Cloud/Edge resource"
            title="Adaptive, interoperable Fog/Cloud/Edge resource provisioning for execution of Big Data
            pipelines"
          >
            To develop algorithms for optimized runtime provisioning of
            resources made available on the marketplace on the Computing
            Continuum (Cloud, Edge, Fog), facilitating omnidirectional data
            drifts among the data pipelines.
          </ObjectivesItem>
        </div>
      </div>
    );
  }
}
