import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { MuiThemeProvider } from "@material-ui/core";

import MetaTags from "../../components/metatags/MetaTags";
import PatchedPagination from "../../components/patchedpagination/PatchedPagination";
import { tableTheme } from "../../templates/themes";
import { ExportCsvTable, ExportPdfTable } from "./ExportFile";
import { ColumnFieldsSP } from "./data/DataSP";
import ColumnsSP from "./columns/ColumnsSP";
import TableAction from "./TableAction";
import {
  FILTER_ROW_ID, ICONS_TOOLTIP,
  initializeFilterRow, handleFilterClick,
  CustomAddButton, CustomExportButton
} from "./CustomIcons";

import getSP from "../../utils/api/SP/getSP";
import addSP from "../../utils/api/SP/addSP";
import updateSP from "../../utils/api/SP/updateSP";
import deleteSP from "../../utils/api/SP/deleteSP";

import "./Table.css";

const SCIENTIFIC_PUBLICATIONS_ID = "scientific-publications";

export default function ScientificPublications() {
  const [table, setTable] = useState({
    columns: [],
    data: [],
    isLoading: true,
  });

  const getTableData = async () => {
    let tableData = await getSP.getSP();
    tableData.map((data) => {
      if (data.publicationDOI === "undefined") {
        data.publicationDOI = undefined;
      }

      if (Array.isArray(data.publicationType) && data.publicationType.length > 0) {
        data.publicationType = data.publicationType[0];
      }

      return data;
    });

    setTable({
      columns: ColumnsSP,
      data: tableData,
      isLoading: false,
    });
  };

  const AddTableRow = async (newData, resolve) => {
    let rowData = await addSP
      .addImage(newData, newData.photo?.file)
      .then((result) => {
        return result;
      });

    resolve();

    setTable((prevState) => {
      const data = [...prevState.data];
      data.push(rowData);
      return { ...prevState, data };
    });
  };

  const UpdateTableRow = async (newData, oldData, resolve) => {
    if (oldData) {

      let updatedData;

      // checks if photo field was changed
      if (oldData.photo?._id === newData.photo?.id) {
        updatedData = await updateSP.updateSP(oldData, newData, newData.photo.id);
      } else {
        updatedData = await updateSP.update(oldData, newData, newData.photo?.file);
      }

      if (updatedData && updatedData.publicationDOI === "undefined") {
        updatedData.publicationDOI = undefined;
      }

      resolve();

      if (updatedData) {
        setTable((prevState) => {
          const data = [...prevState.data];
          data[data.indexOf(oldData)] = updatedData;
          return { ...prevState, data };
        });
      }
    }
  };

  const DeleteTableRow = async (oldData, resolve) => {
    await deleteSP.deleteSP(oldData);

    resolve();

    setTable((prevState) => {
      const data = [...prevState.data];
      data.splice(data.indexOf(oldData), 1);
      return { ...prevState, data };
    });
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    initializeFilterRow(SCIENTIFIC_PUBLICATIONS_ID, FILTER_ROW_ID);
  }, []);

  return (
    <div id={SCIENTIFIC_PUBLICATIONS_ID} className="reports-table">
      <MuiThemeProvider theme={tableTheme}>
        <MetaTags title="Scientific Publications | Reporting | DataCloud Project" />

        <MaterialTable
          title="Scientific Publications"
          columns={table.columns}
          data={table.data}
          components={{
            Action: props => <TableAction {...props} />,
            Pagination: PatchedPagination
          }}
          icons={{
            Add: CustomAddButton,
            Export: CustomExportButton
          }}
          actions={[
            {
              icon: "filter_list",
              tooltip: ICONS_TOOLTIP.FILTER,
              isFreeAction: true,
              onClick: () => handleFilterClick(SCIENTIFIC_PUBLICATIONS_ID, FILTER_ROW_ID)
            }
          ]}
          isLoading={table.isLoading}
          options={{
            filtering: true,
            paging: true,
            paginationType: 'stepped',
            search: false,
            exportMenu: [
              {
                label: "Export PDF",
                exportFunc: (cols) =>
                  ExportPdfTable(cols, table.data, "Scientific Publications", ColumnFieldsSP, true),
              },
              {
                label: "Export CSV",
                exportFunc: (cols) =>
                  ExportCsvTable(cols, table.data, "Scientific Publications", ColumnFieldsSP, true),
              },
            ],
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                AddTableRow(newData, resolve);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                UpdateTableRow(newData, oldData, resolve);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                DeleteTableRow(oldData, resolve);
              }),
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
