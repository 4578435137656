import React from "react";
import { Divider, Card } from "@material-ui/core";

import "./CardItem.css";

function handleClick(urlTitle, type, resource) {
  if (window.location.pathname === "/") {
    window.location.href += "whats-new/" + type + "/" + urlTitle;
  } else if (
    window.location.pathname === "/whats-new/all" ||
    window.location.pathname === "/whats-new/news" ||
    window.location.pathname === "/whats-new/events" ||
    window.location.pathname === "/whats-new/blog"
  ) {
    window.location.pathname += "/" + urlTitle;
  } else if (window.location.pathname === "/resources/" + resource) {
    window.location.href += "/" + urlTitle;
  }
}

export default function CardItem(props) {
  return (
    <Card
      className="card-item"
      onClick={(event) => {
        handleClick(props.urlTitle, props.type, props.resource);
      }}
    >
      <div className="card-item-img">
        <img src={props.imgSrc} alt={props.imgAlt}></img>
      </div>
      <div className="card-body">
        <div className="card-item-content">
          {props.type ? <h4>{props.type}</h4> : null}
          <h3>{props.title}</h3>
        </div>
        <div className="card-item-footer">
          <Divider className="divider" />
          {props.company ? <p className="company">{props.company}</p> : null}
          <p className="footer">{props.footer}</p>
        </div>
      </div>
    </Card>
  );
}
