import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText, Select, Input, MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  formControl: {
    maxWidth: 300
  },
  root: {
    fontSize: 13,
    maxWidth: "100%"
  },
  selectMenu: {
    padding: "8px 15px"
  }
});

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  }
};

function getStyles(name, personName) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? 400 : 600,
  };
}

export default function MultipleSelect(props) {
  const classes = useStyles();

  const currentValue = (props.value === undefined ? [] : props.value);

  return (
    <FormControl 
      error={Boolean(props.error)}
      classes={{root: classes.formControl}}
      style={{ width: "100%" }} 
    >
      <Select
        classes={{ root: classes.root }}
        multiple
        value={currentValue}
        onChange={(event) => props.onChange(event.target.value)}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {Object.keys(props.lookup).map((key) => (
          <MenuItem
            classes={{ root: classes.selectMenu }}
            key={key}
            value={key} 
            style={getStyles(key, currentValue)}
          >
            {props.lookup[key]}
          </MenuItem>
        ))}
      </Select>
      {Boolean(props.helperText) && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}