import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class SAPIENZA extends Component {
  render() {
    return (
      <div>
        <MetaTags title = "Sapienza University of Rome | Consortium | DataCloud Project" />
        <PartnersHeader
          title="Sapienza University of Rome"
          color="#831429"
        ></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              Sapienza University of Rome (URO) has established itself as one of
              the most prestigious academic institution in science and
              technology worldwide.
              <br />
              <br />
              Dipartimento di Ingegneria Informatica Automatica e Gestionale
              Antonio Ruberti (DIAG) is a multidisciplinary research and
              education center that hosts more than 70 Sapienza faculties and 10
              research labs from the School in Computer Science & Engineering.
              Basic research is the main goal of DIAG, with a strong emphasis on
              interdisciplinary research, on applications that stimulate basic
              research, and with a specific attention to technology transfer and
              dissemination of results. From a research perspective, DIAG has
              strong experience in Big Data, Process Management and Mining,
              Artificial Intelligence, and Cybersecurity. <br />
              <br />
              In 2017, DIAG has been awarded as “Excellent Department” by the
              Italian Ministry for Education and Research (MIUR), on the basis
              of the high quality research, education and knowledge transfer
              activities performed over the last 5 years, and allowing special
              funding for new positions and laboratories.
            </div>
            <div className="partner-button">
              <a
                href="https://www.uniroma1.it/en/pagina-strutturale/home"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              URO will lead the research related to the realization of techniques and
              tools for Big Data Pipeline discovery, management, analytics and
              visualization. In addition, it will also lead the development of
              DIS-PIPE and contribute to DEF-PIPE and SIM-PIPE.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
