import moment from "moment";

import { validURL } from "../../utils/utils";
import { ColumnFieldsSP } from "./data/DataSP";

const inputRegexTest = (text) => {
  const patt = /^(([A-Za-z\u00C0-\u00FF].\s?)+\s[A-Za-z\u00C0-\u00FF'-]{2,}(,\s)?)*([A-Za-z\u00C0-\u00FF].\s?)+\s[A-Za-z\u00C0-\u00FF'-]{2,}$/g;

  // const patt = /^(([A-Za-z].\s?)+\s[A-Za-z'-]+(,\s)?)+$/g;
  return patt.test(text);
}

const errorMessage  = (informativeText, errorText) => {
  const errorMessage = (
    <>
      <span>{errorText}</span><br/>
      <span className="green-text">{informativeText}</span>
    </>
  );

  return errorMessage;
}

const Validation = {
  ValidateDate(field, data) {
    if (!data[field]) {
      return { isValid: false, helperText: "You must pick a date!" };
    } else {
      return true;
    }
  },
  ValidateSelect (field, data) {
    if (!data[field] || data[field].length === 0) {
      return { isValid: false, helperText: "You must pick one option!" };
    } else {
      return true;
    }
  },
  ValidateMultipleSelect (field, data) {
    if (!data[field] || data[field].length === 0) {
      return { isValid: false, helperText: "You must pick at least one option!" };
    } else {
      return true;
    }
  },
  ValidateTextField (field, data) {
    if (!data[field]) {
      return { isValid: false, helperText: "You must provide a input!" };
    } else {
      return true;
    }
  },
  ValidateFile (field, data) {
    if (!data[field]) {
      return { isValid: false, helperText: "You must provide a file!" };
    } else {
      return true;
    }
  },
  ValidatePositiveNumber (field, data) {
    if (!data[field] && data[field] !== 0) {
      return { isValid: false, helperText: "You must provide a number!" };
    } else if (data[field] < 0) {
      return { isValid: false, helperText: "Number must be positive!" };
    } else {
      return true;
    }
  },
  ValidateLink (field, data) {
    if (!data[field] || !validURL(data[field])) {
      return { isValid: false, helperText: "You must provide a valid link!" };
    } else {
      return true;
    }
  },
  ValidateStartDate(startField, endField, data) {
    if (!data[startField]) {
      return { isValid: false, helperText: "You must pick a date!" }
    } else if (data[endField]) {
      const startDate = moment(data[startField]);
      const endDate = moment(data[endField]);

      if (endDate < startDate) {
        return { isValid: false, helperText: "End date cannot be before start date!" }
      } else return true;
    } else return true;
  },
  ValidateEndDate(startField, endField, data) {
    if (!data[endField]) {
      return { isValid: false, helperText: "You must pick a date!" }
    } else if (data[startField]) {
      const startDate = moment(data[startField]);
      const endDate = moment(data[endField]);

      if (endDate < startDate) {
        return { isValid: false, helperText: "End date cannot be before start date!" }
      } else return true;
    } else return true;
  },
  ValidateAuthors (field, data) {
    const informativeText = (<>Names separated by commas.<br/> Example: J. Doe, J. Smith</>);

    if (!data[field]) {
      const message = "You must provide an input!";
      return { isValid: false, helperText: errorMessage(informativeText, message) };
    } else if (!inputRegexTest(data[field])) {
      const message = "Input is not valid!";
      return { isValid: false, helperText: errorMessage(informativeText, message) };
    } else {
      return { isValid: true, helperText: (<span>{informativeText}</span>) };
    }
  },
  ValidateCapitalizedInput (field, data) {
    const informativeText = (<>Text must be capitalized.<br/> Example: East of Eden</>);

    if (!data[field]) {
      const message = "You must provide an input!";
      return { isValid: false, helperText: errorMessage(informativeText, message) };
    } else {
      return { isValid: true, helperText: (<span>{informativeText}</span>) };
    }
  },
  ValidateJournalName (field, data) {
    const publicationType = data[ColumnFieldsSP.publicationType];
    const isThesis = publicationType === "masterThesis" || publicationType === "phdThesis";
    if (isThesis) { return true; }

    const informativeText = (<>Text must be capitalized.<br/> Example: East of Eden</>);
    if (!data[field]) {
      const message = "You must provide an input!";
      return { isValid: false, helperText: errorMessage(informativeText, message) };
    } else {
      return { isValid: true, helperText: (<span>{informativeText}</span>) };
    }
  },
  ValidateDOI (field, data) {
    const publicationType = data[ColumnFieldsSP.publicationType];
    const isThesis = publicationType === "masterThesis" || publicationType === "phdThesis";
    if (isThesis) { return true; }

    const informativeText = (<>Please provide a DOI name.<br/> Example: 10.1000/xyz123</>);
    if (!data[field]) {
      const message = "You must provide an input!";
      return { isValid: false, helperText: errorMessage(informativeText, message) };
    } else {
      return { isValid: true, helperText: (<span>{informativeText}</span>) };
    }
  },
}

export default Validation;