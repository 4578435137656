import React, {Component} from "react";
import ReactMarkdown from "react-markdown";

import Header from "../../components/individualheader/Header";
import MetaTags from "../../components/metatags/MetaTags";
import Gallery from "../../templates/gallery/Gallery";

import resourcesImg from "../../assets/images/resources.jpg";

import "./IndividualResources.css";

import getResources from "../../utils/api/resources-get";

export default class IndividualResources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
      img: [],
      video: [],
      file: [],
      type: [],
      loading: false,
    };

    this.convertDate = this.convertDate.bind(this);
  }

  async componentDidMount() {
    let resources = await getResources.getResources();

    //checks if component id matches id in params
    for (var i = 0; i < resources.length; i++) {

      const convertedTitle = resources[i].title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[-]+/g, "-")
        .replace(/[^\w-]+/g, "");

      if (convertedTitle === this.props.match.params.title) {

        let type = resources[i].type.replace(
          /[A-Z]/g,
          (m) => "-" + m.toLowerCase()
        );

        this.setState({
          resources: resources[i],
          img: resources[i].image,
          video: resources[i].video,
          file: resources[i].file,
          type: type,
        });
      }
    }

    this.setState({loading: true});
  }

  convertDate(date) {
    var options = {year: "numeric", month: "long", day: "numeric"};
    return new Date(date).toLocaleDateString([], options);
  }

  render() {
    return (
      <>
        <MetaTags title={this.state.resources?.title} />

        {/* Resources Page header image and title */}
        <Header
          img={resourcesImg}
          title={this.state.resources.title}
          type={this.state.resources.type}
        ></Header>

        {/* Content Page Wrapper => Defines background and top/bottom padding */}
        <div className="content-page-wrapper">
          {/* Content Page Content => Centered div containing resources content */}
          <div className="content-page-section">
            <div className="content">
              <ReactMarkdown
                source={this.state.resources.abstract}
              ></ReactMarkdown>

              {Object.keys(this.state.file).length !== 0 ? (
                <div className="resource-file-section">
                  <div className="file-text">File available here: </div>
                  <div className="file-text">
                    {this.state.file.map((files) => {
                      return (
                        <a
                          key={files.id}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={window.$apiUrl + files.url}
                          className="file-link"
                        >
                          {files.name}
                        </a>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.state.resources.link ? (
                <div className="resource-link-section">
                  <div className="link-text">
                    For more information visit this link:{" "}
                  </div>
                  <div className="link-text">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.resources.link}
                      className="link"
                    >
                      {this.state.resources.link}
                    </a>
                  </div>
                </div>
              ) : null}

              {this.state.loading === true ? <Gallery {...this.state} /> : null}

              {this.state.resources.author ? (
                <div className="author">
                  <div className="author-text">
                    This article was written by{" "}
                  </div>
                  <div className="author-name">
                    {this.state.resources.author}
                  </div>
                </div>
              ) : null}

              <div className="published-resource">
                <div className="published-text">Published in </div>
                <div className="published-date">
                  {this.convertDate(this.state.resources.published)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
