const PartnersList = {
  "sintef": "SINTEF",
  "uro": "URO",
  "kth": "KTH",
  "aau": "AAU",
  "iex": "IEX",
  "jot": "JOT",
  "mog": "MOG",
  "ubi": "UBI",
  "tlu": "TLU",
  "cer": "CER",
  "bos": "BOS"
};

const TargetAudience = {
  "science": "Science",
  "industry": "Industry",
  "education": "Education",
  "policyMaking": "Policy Making",
  "softwareDevelopment": "Software Development",
  "generalPublic": "General Public",
  "customers": "Customers"
};

module.exports = { PartnersList, TargetAudience };