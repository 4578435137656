import React, { Component } from "react";
import { connect } from "react-redux";
import { AppBar, Toolbar, Typography, Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { UserActions } from "../../redux/actions";

import DropdownItem from "./DropdownItem"
import NavbarItem from "./NavbarItem";

import logo from "../../assets/logo/012_DataCloud.png";
import UserGuide from "../../assets/files/reporting_tool.pdf";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class NavbarReports extends Component {
  logout() {
    localStorage.removeItem(window.$userJwt);
    this.props.updateLoginState(false);
  }

  render() {
    return (
      <AppBar position="sticky" color="secondary" className="navbar navbar-reports">
        <Toolbar className="toolbar">
          <div className="navbar-brand">
            <Link
              variant="inherit"
              color="inherit"
              underline="none"
              component={NavLink}
              to="/reports"
            >
              <img className="navbar-logo" src={logo} alt="DataCloud Logo" />
            </Link>
          </div>

          <div className="navbar-fill" />

          <div className="main-menu">
            <Typography
              className="navbar-link"
              component="div"
              variant="body2"
              color="textSecondary"
            >
              <NavbarItem link="/reports/scientific-publications">Scientific Publications</NavbarItem>

              <NavbarItem link="/reports/communication-activities">Communication Activities</NavbarItem>

              <NavbarItem link="/reports/events-meetings">Events &amp; Meetings</NavbarItem>

              <div className="dropdown">
                {/* Options dropdown button */}
                <Link
                  className="dropbtn main-link"
                  variant="inherit"
                  color="inherit"
                  underline="none"
                  to="#"
                >
                  Options <ExpandMoreIcon />
                </Link>

                {/* Options dropdown content */}
                <div className="dropdown-content reports-dropdown-content">
                  <div className="dropdown-placeholder" />

                  <div className="dropdown-links">
                    <DropdownItem externalLink={UserGuide}>
                      User Guide
                    </DropdownItem>

                    <div className="dropdown-item">
                      <Link
                        href="#"
                        onClick={this.logout.bind(this)}
                        variant="inherit"
                        color="inherit"
                        underline="none"
                      >
                        Sign out
                      </Link>
                    </div>

                  </div>

                </div>
              </div>
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapActionsToProps = {
  updateLoginState: UserActions.updateLoginState
};

export default connect(null, mapActionsToProps)(NavbarReports);
