import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { MuiThemeProvider } from "@material-ui/core";

import MetaTags from "../../components/metatags/MetaTags";
import PatchedPagination from "../../components/patchedpagination/PatchedPagination";
import { tableTheme } from "../../templates/themes";
import { ExportCsvTable, ExportPdfTable } from "./ExportFile";
import { ColumnFieldsEM } from "./data/DataEM";
import ColumnsEM from "./columns/ColumnsEM";
import TableAction from "./TableAction";
import { 
  FILTER_ROW_ID, ICONS_TOOLTIP,
  initializeFilterRow, handleFilterClick,
  CustomAddButton, CustomExportButton
} from "./CustomIcons";

import getEM from "../../utils/api/EM/getEM"
import addEM from "../../utils/api/EM/addEM"
import updateEM from "../../utils/api/EM/updateEM"
import deleteEM from "../../utils/api/EM/deleteEM"

import "./Table.css";

const EVENT_MEETINGS_ID = "event-meetings";

export default function CommunicationActivities() {
  const [table, setTable] = useState({
    columns: [],
    data: [],
    isLoading: true
  });

  const getTableData = async () => {
    const tableData = await getEM.getEM();

    setTable({
      columns: ColumnsEM,
      data: tableData,
      isLoading: false
    });
  }

  const AddTableRow = async (newData, resolve) => {
  
    const rowData = await addEM.addImage(newData, newData.photo?.file).then((result) => {
      return result;
    });

    resolve();

    setTable((prevState) => {
      const data = [...prevState.data];
      data.push(rowData);
      return { ...prevState, data };
    });
  }

  const UpdateTableRow = async (newData, oldData, resolve) => {
    if (oldData) {
      
      let updatedData;

      // checks if photo field was changed
      if (oldData.photo?._id === newData.photo?._id) {
        updatedData = await updateEM.updateEM(oldData, newData, newData.photo.id);
      } else {
        updatedData = await updateEM.update(oldData, newData, newData.photo?.file);
      }

      resolve();

      if (updatedData) {
        setTable((prevState) => {
          const data = [...prevState.data];
          data[data.indexOf(oldData)] = updatedData;
          return { ...prevState, data };
        });
      }
    }
  }

  const DeleteTableRow = async (oldData, resolve) => {
    
    if (oldData.photo?.file) {
      await deleteEM.deleteFileImage(oldData.photo?.file); //delete content when the page is not refreshed
    } else {
      await deleteEM.deleteEM(oldData)
    }

    resolve();
    
    setTable((prevState) => {
      const data = [...prevState.data];
      data.splice(data.indexOf(oldData), 1);
      return { ...prevState, data };
    });
  }

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    initializeFilterRow(EVENT_MEETINGS_ID, FILTER_ROW_ID);
  }, []);

  return (
    <div id={EVENT_MEETINGS_ID} className="reports-table">
      <MuiThemeProvider theme={tableTheme}>
        <MetaTags title="Events &amp; Meetings | Reporting | DataCloud Project" />

        <MaterialTable
          title="Events &amp; Meetings"
          columns={table.columns}
          data={table.data}
          components={{
            Action: props => <TableAction {...props}/>,
            Pagination: PatchedPagination
          }}
          icons={{
            Add: CustomAddButton,
            Export: CustomExportButton
          }}
          actions={[
            {
              icon: "filter_list",
              tooltip: ICONS_TOOLTIP.FILTER,
              isFreeAction: true,
              onClick: () => handleFilterClick(EVENT_MEETINGS_ID, FILTER_ROW_ID)
            }
          ]}
          isLoading={table.isLoading}
          options={{
            filtering: true,
            paging: true,
            paginationType: 'stepped',
            search: false,
            exportMenu: [{
              label: "Export PDF",
              exportFunc: (cols) => ExportPdfTable(cols, table.data, "Events And Meetings", ColumnFieldsEM)
            }, {
              label: "Export CSV",
              exportFunc: (cols) => ExportCsvTable(cols, table.data, "Events And Meetings", ColumnFieldsEM)
            }]
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                AddTableRow(newData, resolve);
            }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                UpdateTableRow(newData, oldData, resolve);
            }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                DeleteTableRow(oldData, resolve);
            })
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
