import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import usePagination from "./PaginationFunctions";
import CardItem from "../../components/carditem/CardItem";

import "./Pagination.css";

export default function PaginationComponent(props) {
  let [page, setPage] = useState(1);
  let [msg, setMsg] = useState("");
  const itemsPerPage = 12;
  const count = Math.ceil(props.data.length / itemsPerPage);
  const _DATA = usePagination(props.data, itemsPerPage);
  const twoChildElems = (props.data.length < 3);

  const handleChange = (e, page) => {
    setPage(page);
    _DATA.skip(page);
  };

  function convertDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  function emptyMessage() {
    // If state is empty returns empty message
    if (Object.keys(props.data).length === 0) {
      return setMsg("There is no items available yet");
    } else {
      return setMsg("");
    }
  }

  function convertTitle(title) {
    return title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[-]+/g, "-")
    .replace(/[^\w-]+/g, "");
  }

  useEffect(() => {
    emptyMessage();
  });

  return (
    <div>
      {props.loading ? (
        msg !== "" ? (
          <div className="news-empty-msg">{msg}</div>
        ) : (
          <div className="pagination-content">
            <Grid 
              className={twoChildElems ? "centered-content" : "grid-container"}
              container spacing={5} direction="row"
            >
              {_DATA.currentData().map((v) => {
                return (
                  <Grid item key={v.id} xs={12} sm={6} md={4}>
                    <CardItem
                      urlTitle={convertTitle(v.title)}
                      imgSrc={v.image.slice(0, 1).map((media) => {
                        return window.$apiUrl + media.url;
                      })}
                      imgAlt={v.image.slice(0, 1).map((media) => {
                        return media.alternativeText;
                      })}
                      resource={props.resource}
                      type={!props.resource ? v.type : " "}
                      title={v.title}
                      footer={convertDate(v.published)}
                    ></CardItem>
                  </Grid>
                );
              })}
            </Grid>
            <div className="pagination">
              <Pagination
                count={count}
                size="large"
                page={page}
                color="primary"
                onChange={handleChange}
              />
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}
