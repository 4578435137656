import React from "react";
import { Paper } from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

import "./Cards.css";

function CardItem(props) {
  return (
    <Paper elevation={3} square={true}>
      <div className="contact-info">
        <div className="contact-icon">{props.icon}</div>

        <h3 className="contact-title">{props.title}</h3>
        <p className={props.class}>{props.children}</p>
      </div>
    </Paper>
  );
}

export default function Cards() {
  return (
    <div className="contact-cards-wrapper">
      <CardItem icon={<EmailIcon htmlColor="#FFFFFF" />} title="Email" class="email">
        <a href="mailto:Dumitru.Roman@sintef.no" className="contact-link">Dumitru.Roman@sintef.no</a>
        <span className="contact-email-text">Dumitru Roman - Project coordinator</span>
      </CardItem>

      <CardItem icon={<TwitterIcon htmlColor="#FFFFFF" />} title="Follow us">
        <a
          href="https://twitter.com/DataCloud2020"
          target="_blank"
          rel="noreferrer"
          className="contact-link"
        >
          twitter.com/DataCloud2020
        </a>
      </CardItem>

      <CardItem icon={<YouTubeIcon htmlColor="#FFFFFF" />} title="Subscribe">
        <a
          href="https://www.youtube.com/channel/UCVUkE2oBHCgTGB3QypNOPYw"
          target="_blank"
          rel="noreferrer"
          className="contact-link"
        >
          YouTube - DataCloud Project
        </a>
      </CardItem>

      {/* <CardItem icon={<LinkedInIcon htmlColor="#FFFFFF" />} title="Follow us">
        <a
          href="https://linkedin.com/"
          target="_blank"
          rel="noreferrer"
          className="contact-link"
        >
          linkedin.com/
        </a>
      </CardItem> */}
    </div>
  );
}
