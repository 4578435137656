import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#00FFFF",
    borderRadius: "18px",
    color: "black",
    height: 35,
    padding: "0 25px",
    "&:hover": {
      backgroundColor: "#00FFFF",
      opacity: 0.8,
    }
  },
  label: {
    textTransform: "capitalize",
    fontSize: "13px",
    fontWeight: 300,
    letterSpacing: "0.65px"
  },
});

export default function BackButton(props) {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {props.children}
    </Button>
  );
}