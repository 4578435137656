import React, { Component } from "react";

import MobileDropdown from "./MobileDropdown";

const items = [
    { link: "/contacts", dropdownTitle: "Get in Touch" },
    { externalLink: "https://www.youtube.com/channel/UCVUkE2oBHCgTGB3QypNOPYw", dropdownTitle: "YouTube" },
    { externalLink: "https://twitter.com/DataCloud2020", dropdownTitle: "Twitter" },
];

export default class ContactsDropdown extends Component {

  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/contacts")) {
      return true;
    }

    return false;
  };

  handleClose() {
    this.props.handleClose();
  }

  render() {
    return (
      <MobileDropdown
        matchResourcesPages={this.matchResourcesPages.bind(this)}
        title="Contacts"
        items={items}
        closeDrawer={this.handleClose.bind(this)}
      ></MobileDropdown>
    );
  }
}
