import React, { Component } from "react";

import MobileDropdown from "./MobileDropdown";

const items = [
  { link: "/project/about-the-project", dropdownTitle: "About the project" },
  { link: "/project/toolbox", dropdownTitle: "Toolbox" },
  { link: "/project/business-cases", dropdownTitle: "Business Cases" },
  { link: "/project/advisory-board", dropdownTitle: "Advisory Board" }
];

export default class ProjectDropdown extends Component {
  
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/project")) {
      return true;
    }

    return false;
  };

  handleClose() {
    this.props.handleClose();
  }

  render() {
    return (
      <MobileDropdown
        matchResourcesPages={this.matchResourcesPages.bind(this)}
        title="Project"
        items={items}
        closeDrawer={this.handleClose.bind(this)}
      ></MobileDropdown>
    );
  }
}
