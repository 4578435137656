import React, { useState } from "react";
import {
  Toolbar,
  IconButton,
  Drawer,
  Link,
  Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import NavbarItem from "../navbar/NavbarItem";
import ProjectDropdown from "./ProjectDropdown";
import ResourcesDropdown from "./ResourcesDropdown";
import NewsDropdown from "./NewsDropdown";
import ConsortiumDropdown from "./ConsortiumDropdown";
import ContactsDropdown from "./ContactsDropdown";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import "./MobileNavBar.css";

import logo from "../../assets/logo/012_DataCloud.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "black",
    color: "white",
    padding: "0 20px",
    width: "40vh",
  },
}));

export default function MobileNavBar(props) {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpenState] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpenState(true);
  };
  const handleDrawerClose = () => {
     setDrawerOpenState(false);
  };

  return (
    <Toolbar className="mobile-toolbar">
      <div className="mobile-navbar-brand">
        <Link
          variant="inherit"
          color="inherit"
          underline="none"
          component={NavLink}
          to="/"
        >
          <img className="mobile-navbar-logo" src={logo} alt="DataCloud Logo" />
        </Link>
      </div>

      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-haspopup={true}
        onClick={handleDrawerOpen}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        className="mobile-drawer"
        classes={{ paper: classes.paper }}
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Typography
          className="mobile-navbar-link"
          component="div"
          variant="body2"
          color="textSecondary"
        >
          <div className="icon-button">
            <IconButton
              className="close-icon"
              color="inherit"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div onClick={handleDrawerClose}>
            <NavbarItem link="/">Home</NavbarItem>
          </div>

          <ProjectDropdown handleClose={handleDrawerClose} />

          <NewsDropdown handleClose={handleDrawerClose} />

          <ResourcesDropdown handleClose={handleDrawerClose} />

          <ConsortiumDropdown handleClose={handleDrawerClose} />
          
          <ContactsDropdown handleClose={handleDrawerClose} />
        </Typography>
      </Drawer>
    </Toolbar>
  );
}
