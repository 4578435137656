import React, { Component } from "react";
import { Grid, Link } from "@material-ui/core";

import MetaTags from "../../../components/metatags/MetaTags";
import PageHeader from "../../../components/pageheader/PageHeader";
import AdvisoryBoardItem from "./AdvisoryBoardItem";

import headerImg from "../../../assets/images/header_project.jpg";
import christoph from "../../../assets/images/advisory/christoph_bussler.jpg";
import manish from "../../../assets/images/advisory/manish_parashar.jpg";
import mark from "../../../assets/images/advisory/mark_burgess.jpg";

import "./AdvisoryBoard.css";

export default class AdvisoryBoard extends Component {
    render() {
        return (
            <div>
                <MetaTags title="Advisory Board | Project | DataCloud Project" />

                <PageHeader img={headerImg}>ADVISORY BOARD</PageHeader>

                <div className="advisory-board-wrapper">
                    <div className="advisory-board-project">
                        <Grid className="project-grid" container align="stretch">
                            
                        <Grid item>
                                <AdvisoryBoardItem
                                    alignRight={false}
                                    title="Manish Parashar"
                                    imgSrc={manish}
                                    imgAlt="Manish Parashar"
                                >
                                    <p className="text-item">
                                        Manish Parashar Director of the Scientific Computing and Imaging (SCI) 
                                        Institute, Chair in Computational Science and Engineering, and Professor, 
                                        School of Computing at the University of Utah. His research interests are 
                                        in the broad areas of Parallel and Distributed Computing and Computational 
                                        and Data-Enabled Science and Engineering and has published extensively in 
                                        these areas. He has also deployed software systems that are widely used. 
                                        Manish is the founding chair of the IEEE Technical Consortium on High 
                                        Performance Computing (TCHPC), Editor-in-Chief of the IEEE Transactions 
                                        on Parallel and Distributed Systems and serves on the editorial boards 
                                        and organizing committees of several journals and international conferences 
                                        and workshops. He has received numerous awards for his research and leadership, 
                                        and is Fellow of AAAS, ACM, and IEEE/IEEE Computer Society. For more 
                                        information, please visit <Link href="http://manishparashar.org" target="_blank" rel="noreferrer">http://manishparashar.org</Link>.
                                    </p>
                                </AdvisoryBoardItem>
                            </Grid>

                            <Grid item>
                                <AdvisoryBoardItem
                                    alignRight={true}
                                    title="Christoph Bussler"
                                    imgSrc={christoph}
                                    imgAlt="Christoph Bussler"
                                >
                                    <p className="text-item">
                                        Chris was always fascinated by systems and data integration between on-premise 
                                        systems, private/public clouds and hybrid data architectures. He currently focuses 
                                        on databases, data migration, data integration and data analytics on a large scale 
                                        in private/public clouds. 
                                    </p>
                                    <p className="text-item">
                                        Earlier in his career, Chris contributed to Semantic Web technologies and standards, 
                                        Enterprise Application System Integration (EAI), Business-to-Business Integration (B2B) 
                                        as well as Workflow Management. In addition to implementing these technologies, he wrote 
                                        several books on these topics and published extensively. For more information, please 
                                        visit <Link href="https://www.real-programmer.com" target="_blank" rel="noreferrer">https://www.real-programmer.com</Link>).
                                    </p>
                                </AdvisoryBoardItem>
                            </Grid>

                            <Grid item>
                                <AdvisoryBoardItem
                                    alignRight={false}
                                    title="Mark Burgess"
                                    imgSrc={mark}
                                    imgAlt="Mark Burgess"
                                >
                                    <p className="text-item">
                                        Mark Burgess is a theoretician and practitioner in the area of information systems, whose work 
                                        has focused largely on distributed information infrastructure. He is known particularly for his 
                                        work on Configuration Management and Promise Theory. Mark Burgess was CTO, Founder and original 
                                        author of CFEngine, and in 2018 he founded ChiTek-i, and co founded Aljabr Inc, with Joseph Jacks 
                                        and Petar Maymounkov. He was senior lecturer and then professor of Network and System Administration 
                                        at Oslo University College from 1994-2011. He was the first professor with this title. Mark obtained 
                                        a PhD in Theoretical Physics in Newcastle, for which he received the Runcorn Prize. His current 
                                        research interests include the behaviour of computers as dynamic systems and applying ideas from 
                                        physics to describe computer behaviour. Mark was the principal author of the popular configuration 
                                        management software package CFEngine for 20 years, and was the founder, chairman and CTO of the Cfengine 
                                        company. He has led theoretical and practical contributions to the theory of automation and policy based 
                                        management, including the idea of Operator Convergence and Promise Theory. He is the author of numerous 
                                        books and papers on Network and System Administration and has won several prizes for his work. Today, 
                                        he works as an advisor on science and technology matters all over the world. For more information, please 
                                        visit <Link href="http://markburgess.org" target="_blank" rel="noreferrer">http://markburgess.org</Link>.
                                    </p>
                                </AdvisoryBoardItem>
                            </Grid>

                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}