import React from "react";
import { Grid, Paper } from "@material-ui/core";

export default function ObjectivesItem(props) {
  let objItemClass = "objectives-item";
  if (props.alignRight) {
    objItemClass += " objective-right";
  }

  let objectiveImg = (
    <Grid item xs={12} md={6} className="objective-image">
      <img src={props.imgSrc} alt={props.imgAlt} />
    </Grid>
  );

  return (
    <div className="project-objectives-item">
      <Paper>
        <Grid container className={objItemClass}>
          {!props.alignRight ? objectiveImg : ""}

          <Grid item xs={12} md={6} className="text-section">
            <h2 className="project-objective-title">{props.title}</h2>
            <p className="text-item">{props.children}</p>
          </Grid>

          {props.alignRight ? objectiveImg : ""}
        </Grid>
      </Paper>
    </div>
  );
}
