import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import DropdownItem from "./DropdownItem"

import "./Dropdown.css";

export default class NewsDropdown extends Component {
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/whats-new")) {
      return true;
    }

    return false;
  };

  render() {
    return (
      <div className="dropdown">
        <Link
          className="dropbtn main-link"
          variant="inherit"
          color="inherit"
          underline="none"
          component={NavLink}
          activeClassName="selected"
          isActive={this.matchResourcesPages.bind(this)}
          exact={true}
          to="#"
        >
          What's new
        </Link>

        <div className="dropdown-content news-dropdown-content">
          <div className="dropdown-placeholder" />

          <div className="dropdown-links">
            <DropdownItem link="/whats-new/news">
              News
            </DropdownItem>

            <DropdownItem link="/whats-new/events">
              Events
            </DropdownItem>

            <DropdownItem link="/whats-new/blog">
              Blog
            </DropdownItem>
          </div>
        </div>
      </div>
    );
  }
}
