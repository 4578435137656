import React, { Component } from "react";

import MetaTags from "../../components/metatags/MetaTags";
import PageHeader from "../../components/pageheader/PageHeader";
import headerImg from "../../assets/images/header_resources_resize.jpg";
import Pagination from "../../templates/pagination/Pagination";

import "./Resources.css";

import getResources from "../../utils/api/resources-get";

export default class Deliverables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliverables: [],
      loading: false,
      msg: "",
    };
  }

  async componentDidMount() {
    let resources = await getResources.getResources();

    resources.map((deliverables) => {
      if (deliverables.type === "deliverables") {
        return this.setState((prevState) => ({
          deliverables: [...prevState.deliverables, deliverables],
          msg: "",
        }));
      }
      return null;
    });

    this.setState({ loading: true });

    if (Object.keys(this.state.deliverables).length === 0) {
      return this.setState({
        msg: "There is no Deliverables available yet",
      });
    }
  }

  render() {
    return (
      <div>
        <MetaTags title="Deliverables | Resources | DataCloud Project" />

        <PageHeader img={headerImg}>
          RESOURCES
        </PageHeader>

        {/* Content Page Wrapper => Defines background and top/bottom padding */}
        <div className="resources-page-wrapper">
          {/* Content Page Content => Centered div containing resources content */}
          <div className="resources-page-content">
            <h3 className="resources-title">Deliverables</h3>
            <div>
              {this.state.loading ? (
                this.state.msg !== "" ? (
                  <div className="empty-msg">{this.state.msg}</div>
                ) : (
                  <Pagination
                    data={this.state.deliverables}
                    resource="deliverables"
                    loading={this.state.loading}
                  ></Pagination>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
