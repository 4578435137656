const getEvents = {
  getEvents() {
    return fetch(`${window.$apiUrl}/events?_sort=published_at:DESC&_limit=-1`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonReponse) => {
        return jsonReponse.map((events) => {
          return {
            id: events.id,
            title: events.Title,
            content: events.Description,
            location: events.Location,
            date: events.Date,
            link: events.Link,
            image: events.Images,
            video: events.Video,
            file: events.Files,
            published: events.published_at,
            type: "events",
          };
        });
      });
  },
};

export default getEvents;
