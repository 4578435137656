import React, { Component } from "react";
import { Toolbar, Typography, Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import NavbarItem from "./NavbarItem";
import ProjectDropdown from "./ProjectDropdown";
import ResourcesDropdown from "./ResourcesDropdown";
import NewsDropdown from "./NewsDropdown";
import ConsortiumDropdown from "./ConsortiumDropdown";

import logo from "../../assets/logo/012_DataCloud.png";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import "./NavBar.css";

export default class NavBar extends Component {
  render() {
    return (
      <Toolbar className="toolbar">
        <div className="navbar-brand">
          <Link
            variant="inherit"
            color="inherit"
            underline="none"
            component={NavLink}
            to="/"
          >
            <img className="navbar-logo" src={logo} alt="DataCloud Logo" />
          </Link>
        </div>

        <div className="navbar-fill" />

        <div className="main-menu">
          <Typography
            className="navbar-link"
            component="div"
            variant="body2"
            color="textSecondary"
          >
            <NavbarItem link="/">Home</NavbarItem>

            <ProjectDropdown />

            <NewsDropdown />

            <ResourcesDropdown />

            <ConsortiumDropdown />

            <NavbarItem link="/contacts">Contacts</NavbarItem>

            <div className="navbar-item">
              <a
                className="social-media-link"
                href="https://www.youtube.com/channel/UCVUkE2oBHCgTGB3QypNOPYw"
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeIcon htmlColor="#FFF" />
              </a>
            </div>

            <div className="navbar-item">
              <a
                className="social-media-link"
                href="https://twitter.com/DataCloud2020"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon htmlColor="#FFF" />
              </a>
            </div>

          </Typography>
        </div>
      </Toolbar>
    );
  }
}
