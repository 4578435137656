import React, { Component } from "react";

import MobileDropdown from "./MobileDropdown";

const items = [
  { link: "/consortium/who-we-are", dropdownTitle: "Who we are" },
  { link: "/consortium/sintef", dropdownTitle: "Sintef" },
  { link: "/consortium/sapienza", dropdownTitle: "Sapienza" },
  {
    link: "/consortium/universitat-klagenfurt",
    dropdownTitle: "Uni Klagenfurt",
  },
  { link: "/consortium/kth", dropdownTitle: "KTH" },
  { link: "/consortium/iexec", dropdownTitle: "iExec" },
  { link: "/consortium/ubitech", dropdownTitle: "Ubitech" },
  { link: "/consortium/jot-im", dropdownTitle: "Jot" },
  { link: "/consortium/mog-technologies", dropdownTitle: "MOG Technologies" },
  { link: "/consortium/catalano", dropdownTitle: "Catalano" },
  { link: "/consortium/tellu", dropdownTitle: "Tellu" },
  { link: "/consortium/bosch", dropdownTitle: "Bosch" },
];

export default class ConsortiumDropdown extends Component {
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/consortium")) {
      return true;
    }

    return false;
  };

  handleClose() {
    this.props.handleClose();
  }

  render() {
    return (
      <div >
        <MobileDropdown
          matchResourcesPages={this.matchResourcesPages.bind(this)}
          title="Consortium"
          items={items}
          closeDrawer={this.handleClose.bind(this)}
        ></MobileDropdown>
      </div>
    );
  }
}
