import React from "react";

import sintefImg from "../../assets/images/partners_sintef.png";
import sapienzaImg from "../../assets/images/partners_sapienza.png";
import uniklaImg from "../../assets/images/partners_unikla.png";
import kthImg from "../../assets/images/partners_kth.png";
import iexecImg from "../../assets/images/partners_iExec.png";
import ubitechImg from "../../assets/images/partners_ubitech.png";
import jotImg from "../../assets/images/partners_jot.png";
import mogImg from "../../assets/images/partners_mog.png";
import catalanoImg from "../../assets/images/partners_catalano.png";
import telluImg from "../../assets/images/partners_tell_u.png";
import boschImg from "../../assets/images/partners_bosch.png";

export default function FooterPartners() {
  return (
    <div className="footer-partners">

      <div className="footer-img-wrap">
        <img src={sintefImg} alt="SINTEF"/>
      </div>

      <div className="footer-img-wrap">
        <img src={sapienzaImg} alt="SAPIENZA Università di Roma"/>
      </div>

      <div className="footer-img-wrap">
        <img src={uniklaImg} alt="Universitat Klagenfurt"/>
      </div>

      <div className="footer-img-wrap">
        <img src={kthImg} alt="Kungl Terkniska Hogskolan"/>
      </div>

      <div className="footer-img-wrap">
        <img src={iexecImg} alt="iExec"/>
      </div>

      <div className="footer-img-wrap">
        <img src={ubitechImg} alt="UBITECH"/>
      </div>

      <div className="footer-img-wrap">
        <img src={jotImg} alt="JOT"/>
      </div>

      <div className="footer-img-wrap">
        <img src={mogImg} alt="MOG Technologies"/>
      </div>

      <div className="footer-img-wrap">
        <img src={catalanoImg} alt="Catalano"/>
      </div>

      <div className="footer-img-wrap">
        <img src={telluImg} alt="Tell.U"/>
      </div>

      <div className="footer-img-wrap">
        <img src={boschImg} alt="BOSCH"/>
      </div>

    </div>
  );
}