import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import ReportsLayout from "./ReportsLayout";
import BaseLayout from "./BaseLayout";
import ScrollToTop  from "../../components/scrolltotop/ScrollToTop";

export default class AppRouter extends Component {
  onPageChange = ({location}) => {
    if (typeof window.ga === "function") {
      const page = location.pathname + location.search;
      ReactGA.send({ hitType: "pageview", page: page });
    }
    return;
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <Route path="/" render={this.onPageChange.bind(this)} />
        <Switch>
          <Route path="/reports">
            <ReportsLayout />
          </Route>
          <Route path="/">
            <BaseLayout />
          </Route>
        </Switch>
      </Router>
    );
  }
}
