import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class iExec extends Component {
  render() {
    return (
      <div>
        <MetaTags title="iExec | Consortium | DataCloud Project" />
        <PartnersHeader title="iExec" color="#F5C902"></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              iExec Blockchain Tech is a French SME based in Lyon, France, that
              built the first decentralized marketplace in which entities (e.g.
              traditional cloud providers, research centers and even
              individuals) can contribute and monetize Cloud Computing resources
              (CPU, GPU), Decentralized Applications (Dapps) and data-sets (Data
              Renting) in a secure and confidential way, ensuring the
              confidentiality and ownership of data. <br />
              <br />
              iExec combines Ethereum Smart Contracts, a unique
              Proof-of-Contribution (PoCo) protocol and Trusted Execution
              Environments (TEE) to ensure trust between providers and consumers
              of resources. In addition to the public marketplace running on the
              main Ethereum Network, iExec designs and deploys custom tailored
              decentralized computing platforms to industrial consortiums with
              specific governance rules.
              <br />
              <br /> Created in 2016 by Dr Gilles Fedak, Dr Oleg Lodygensky and
              Dr Haiwu He, three academic researchers respectively from Inria,
              CNRS and the Chinese Academy of Science, iExec currently employs
              24 people from 8 different nationalities, including 7 PhDs.
            </div>
            <div className="partner-button">
              <a
                href="https://iex.ec/?utm_source=DataCloud&utm_medium=referral"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              iExec will contribute its wide expertise in Blockchain
              technologies and decentralized cloud computing to the DataCloud
              project. In particular, iExec will lead research related to the
              development of the decentralized cloud computing marketplace, and
              will produce unified interfaces on top of heterogeneous
              decentralized resources. iExec will also promote selected project
              results to international standard bodies such as the EEA and CCC.
              <br />
              <br /> iExec will lead the development of R-MARKET.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
