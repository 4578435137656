import React, { Component } from "react";

import HomeBanner from "./HomeBanner";
import HomeCarousel from "./HomeCarousel";
import MetaTags from "../../components/metatags/MetaTags";
import RecentNews from "./RecentNews";

import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div>
        <MetaTags />

        <HomeBanner />

        <div className="description-wrapper">
          <div className="project-description">
            <div>
              With the recent developments in technologies such as the Internet
              of Things, <b>massive amounts of data are being generated</b> and
              often become Dark Data, i.e., data that are collected but not used
              and turned into value. Big Data pipelines are composite pipelines
              for processing data with non-trivial properties and
              characteristics, commonly referred to as the Vs of <b>Big Data</b>{" "}
              (e.g., volume, velocity, variety, veracity, value,etc.). They are
              essential for leveraging Dark Data, but tapping their potential
              requires going beyond the current approaches and frameworks for
              Big Data processing. At the same time, the Computing Continuum –
              federating Cloud services with emerging Edge and Fog computing
              paradigms – enables new opportunities for supporting Big Data
              pipelines, although challenges remain in the
              <b>
                {" "}
                efficient management of heterogeneous and untrusted resources
                across the Computing Continuum.
              </b>
            </div>

            <div className="highlight">
              The overall vision of the DataCloud project is the creation of a
              novel paradigm for Big Data pipeline processing over heterogeneous
              resources encompassing the Computing Continuum, covering the
              complete lifecycle of managing Big Data pipelines.
            </div>
          </div>
        </div>

        <HomeCarousel />

        <div className="home-news">
          <h2 className="home-title">Recent News</h2>
          {!this.props.items ? (
            <div className="recent-news">
              <RecentNews />
            </div>
          ) : (
            <div className="recent-news-msg">
              Soon we will have some news...
            </div>
          )}
        </div>

        <div className="home-video">
          <h2 className="home-title">About the Project</h2>

          <div className="home-video-container">
            <div className="video-responsive-wrapper">
              <iframe 
                width="560" height="315" 
                src="https://www.youtube.com/embed/cTy-t2DJt7k?&autoplay=1&mute=1&loop=1&playlist=cTy-t2DJt7k" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
