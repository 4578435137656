import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#00406E",
    borderRadius: "19px",
    color: "#FFFFFF",
    height: 42,
    padding: "0 50px",
    "&:hover": {
      backgroundColor: "#00406E",
      opacity: 0.8,
    }
  },
  label: {
    fontSize: "13px",
    fontWeight: 300,
    letterSpacing: "1px",
    textTransform: "none"
  },
});

export default function FormButton(props) {
  const classes = useStyles();

  return (
    <Button
      type="submit"
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {props.children}
    </Button>
  );
}