import React, { Component } from "react";

import MetaTags from "../../components/metatags/MetaTags";
import PageHeader from "../../components/pageheader/PageHeader";
import headerImg from "../../assets/images/header_resources_resize.jpg";
import Pagination from "../../templates/pagination/Pagination";

import "./Resources.css";

import getResources from "../../utils/api/resources-get";

export default class ConferencePapers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conferencePapers: [],
      msg: "",
      loading: false
    };
  }

  async componentDidMount() {
    let resources = await getResources.getResources();

    resources.map((confPapers) => {
      if (confPapers.type === "conferencePapers") {
        return this.setState((prevState) => ({
          conferencePapers: [...prevState.conferencePapers, confPapers],
          msg: "",
        }));
      }
      return null;
    });

    this.setState({ loading: true });

    // If state is empty returns empty message
    if (Object.keys(this.state.conferencePapers).length === 0) {
      return this.setState({
        msg: "There is no Conference Papers available yet",
      });
    }

    
  }

  render() {
    return (
      <div>
        <MetaTags title="Conference Papers | Resources | DataCloud Project" />

        <PageHeader img={headerImg}>
          RESOURCES
        </PageHeader>

        {/* Content Page Wrapper => Defines background and top/bottom padding */}
        <div className="resources-page-wrapper">
          {/* Content Page Content => Centered div containing resources content */}
          <div className="resources-page-content">
            <h3 className="resources-title">Conference Papers</h3>
            {this.state.loading ? (
              this.state.msg !== "" ? (
                <div className="empty-msg">{this.state.msg}</div>
              ) : (
                <Pagination
                  data={this.state.conferencePapers}
                  resource="conference-papers"
                  loading={this.state.loading}
                ></Pagination>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
