import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class CATALANO extends Component {
  render() {
    return (
      <div>
        <MetaTags title = "Ceramica Catalano | Consortium | DataCloud Project" />
        <PartnersHeader title="CATALANO" color="#084131"></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              Ceramica Catalano, founded in 1967 in Fabrica di Roma, is nowadays
              the Italian leading company in sanitary-ware industry. It has a
              unique productive space of 175.000 sqm, with about 260 workers
              employed and modern equipment to reach a productive capacity of
              around 1.000.000 pieces per year, with a catalogue of over 240
              specialized products. These numbers confirm the technological and
              cultural leadership of Catalano in sanitary-ware production in
              Italy, also supported by several international acknowledgments.
              <br />
              <br />
              Catalano, in its 50 years of activity, strengthened values such as
              quality, excellence and innovation with a complete focus on the
              needs of the end user.
            </div>
            <div className="partner-button">
              <a
                href="https://www.catalano.it/"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              Ceramica Catalano will provide the testbed for the ceramic
              deformation use case and will support the Consortium with its
              experience in the management of data-intensive workflows for smart
              manufacturing.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
