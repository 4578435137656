import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import ResponsiveNavbar from "../navbar/ResponsiveNavbar";
import Footer from "../footer/Footer";

import Home from "../../pages/home/Home";
import Project from "../../pages/project/Project";
import BusinessCases from "../../pages/project/businesscases/BusinessCases";
import Toolbox from "../../pages/project/toolbox/Toolbox";
import AdvisoryBoard from "../../pages/project/advisoryboard/AdvisoryBoard";

import News from "../../pages/news/News";
import IndividualNew from "../../pages/individualnew/IndividualNew";
import Contacts from "../../pages/contacts/Contacts";

import Consortium from "../../pages/partners/Partners";
import MOG from "../../pages/individualpartners/Mog";
import SINTEF from "../../pages/individualpartners/Sintef";
import SAPIENZA from "../../pages/individualpartners/Sapienza";
import BOSCH from "../../pages/individualpartners/Bosch";
import JOT from "../../pages/individualpartners/Jot";
import iExec from "../../pages/individualpartners/iExec";
import UBITECH from "../../pages/individualpartners/Ubitech";
import CATALANO from "../../pages/individualpartners/Catalano";
import TELLU from "../../pages/individualpartners/Tellu";
import UKlagenfurt from "../../pages/individualpartners/UKlagenfurt";
import KTH from "../../pages/individualpartners/Kth";

import PageNotFound from "../../pages/pagenotfound/PageNotFound";
import Deliverables from "../../pages/resources/Deliverables";
import JournalArticles from "../../pages/resources/JournalArticles";
import ConferencePapers from "../../pages/resources/ConferencePapers";
import WhitePapers from "../../pages/resources/WhitePapers";
import PresentationSlides from "../../pages/resources/PresentationSlides";
import Others from "../../pages/resources/Others";
import IndividualResources from "../../pages/individualresources/IndividualResources";


export default class BaseLayout extends Component {
  render() {
    return (
      <div>
        <CookieConsent
          buttonStyle={{ 
            backgroundColor: "#00FFFF", 
            color: "#000", 
            fontSize: "14px", 
            borderRadius: "25px",
            padding: "0 15px",
            minHeight: "35px",
          }}
          containerClasses="cookie-container"
          contentClasses="cookie-txt"
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <ResponsiveNavbar />

        <main>
          <Switch>
            <Route exact path="/" component={Home} />

            {/* Project pages */}
            <Route exact path="/project/about-the-project" component={Project} />
            <Route exact path="/project/toolbox" component={Toolbox} />
            <Route exact path="/project/business-cases" component={BusinessCases} />
            <Route exact path="/project/advisory-board" component={AdvisoryBoard} />

            {/* What's news pages */}
            <Route exact path="/whats-new/:params" component={News} />
            <Route exact path="/whats-new/all/:title" component={IndividualNew} />
            <Route exact path="/whats-new/news/:title" component={IndividualNew} />
            <Route exact path="/whats-new/events/:title" component={IndividualNew} />
            <Route exact path="/whats-new/blog/:title" component={IndividualNew} />
            
            <Route exact path="/contacts" component={Contacts} />

            {/* Partners pages */}
            <Route exact path="/consortium/who-we-are" component={Consortium} />
            <Route exact path="/consortium/mog-technologies" component={MOG} />
            <Route exact path="/consortium/sintef" component={SINTEF} />
            <Route exact path="/consortium/sapienza" component={SAPIENZA} />
            <Route exact path="/consortium/bosch" component={BOSCH} />
            <Route exact path="/consortium/jot-im" component={JOT} />
            <Route exact path="/consortium/iexec" component={iExec} />
            <Route exact path="/consortium/ubitech" component={UBITECH} />
            <Route exact path="/consortium/catalano" component={CATALANO} />
            <Route exact path="/consortium/tellu" component={TELLU} />
            <Route exact path="/consortium/universitat-klagenfurt" component={UKlagenfurt} />
            <Route exact path="/consortium/kth" component={KTH} />

            {/* Resources pages */}
            <Route exact path="/resources/deliverables" component={Deliverables} />
            <Route exact path="/resources/deliverables/:title" component={IndividualResources} />
			      <Route exact path="/resources/journal-articles" component={JournalArticles} />
            <Route exact path="/resources/journal-articles/:title" component={IndividualResources} />
			      <Route exact path="/resources/conference-papers" component={ConferencePapers} />
            <Route exact path="/resources/conference-papers/:title" component={IndividualResources} />
			      <Route exact path="/resources/white-papers" component={WhitePapers} />
            <Route exact path="/resources/white-papers/:title" component={IndividualResources} />
			      <Route exact path="/resources/presentation-slides" component={PresentationSlides} />
            <Route exact path="/resources/presentation-slides/:title" component={IndividualResources} />
            <Route exact path="/resources/others" component={Others} />
            <Route exact path="/resources/others/:title" component={IndividualResources} />

            <Route path="*" component={PageNotFound} />
          </Switch>
        </main>

        <Footer />
      </div>
    );
  }
}
