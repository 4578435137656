import React from "react";
import { Grid, Paper } from "@material-ui/core";

export default function AdvisoryBoardItem(props) {
    let advisoryItemClass = "advisory-board-item";
    if (props.alignRight) {
        advisoryItemClass += " advisory-board-right";
    } else {
        advisoryItemClass += " advisory-board-left";
    }

    return (
        <Paper>
            <Grid container className={advisoryItemClass}>
                <Grid item xs={12} sm={12} md={12} lg={5} className="advisory-image advisory-image-left">
                    <img src={props.imgSrc} alt={props.imgAlt} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={7} className="text-section">
                    <h2 className="advisory-title">
                        {props.title}
                    </h2>
                    {props.children}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={5} className="advisory-image advisory-image-right">
                    <img src={props.imgSrc} alt={props.imgAlt} />
                </Grid>
            </Grid>
        </Paper>
    );
}
