import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import MetaTags from "../../components/metatags/MetaTags";

import "./PageNotFound.css";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#00406E",
    borderRadius: "19px",
    color: "#FFFFFF",
    height: 42,
    padding: "0 50px",
    "&:hover": {
      backgroundColor: "#00406E",
      opacity: 0.8,
    }
  },
  label: {
    fontSize: "13px",
    fontWeight: 300,
    letterSpacing: "1px",
    textTransform: "none"
  },
});


export default function PageNotFound(props) {
  const classes = useStyles();

  return (
    <div className="page-404-container">
      <MetaTags title="Page Not Found | DataCloud Project" />

      <div className="page-404">
        <h1 className="page-404-header">404</h1>
        <h2>Page not found.</h2>
        <p>The page you are looking for might have been removed.</p>

        <Link
          underline="none"
          component={NavLink}
          to="/"
        >
          <Button
            classes={{
              root: classes.root,
              label: classes.label,
            }}
          >
            Return to website
          </Button>
        </Link>
      </div>

    </div>
  );
}
