import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, IconButton, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { UserActions } from "../../redux/actions";

import LoginButton from "./LoginButton";
import MetaTags from "../../components/metatags/MetaTags";
import loginUser from "../../utils/api/login-post";

import LogoImg from "../../assets/logo/012_DataCloud.png";
import backgroundImg from "../../assets/images/background_img.png";
import CloseIcon from "@material-ui/icons/Close";

import "./Login.css";

const useStyles = makeStyles({
  input: {
    color: "white",
    fontSize: 14,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent"
    }

  },
  label: {
    fontSize: 14,
    letterSpacing: "0.7px",
    "&.Mui-focused": {
      color: "white"
    }
  }
});

export default function Form(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState({
    message: "",
    open: true,
  });

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await loginUser({ identifier: email, password: password });
    if (response && response.jwt) {
      const userJwt = response.jwt;
      localStorage.setItem(window.$userJwt, userJwt);
      setStatus({
        open: false,
        message: "",
      });

      dispatch(UserActions.updateLoginState(true));
    } else if (response && response.statusCode) {
      setEmail("");
      setPassword("");
      setStatus({
        open: true,
        message: "Invalid credentials",
      });
    }
  }

  function getStatusMessage() {
    let statusMessageElem = <span></span>;

    if (status.message === "") {
      return statusMessageElem;
    }

    statusMessageElem = (
      <Collapse in={status.open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setStatus((prevState) => ({
                  ...prevState,
                  open: false,
                }));
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {status.message}
        </Alert>
      </Collapse>
    );

    return statusMessageElem;
  }

  const statusMessageElem = getStatusMessage();

  return (
    <>
      <MetaTags
        title="Login | Reporting | DataCloud Project"
      />
      <div className="login-backdrop" />
      <div className="login-container" style={{backgroundImage: `url("${backgroundImg}")`}}>

        <div className="alert-container">
          {statusMessageElem}
        </div> 

        <div className="login-form">
          <div className="form-logo">
            <img src={LogoImg} alt="DataCloud Logo" />
          </div>

          <form
            className="login-form-body"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="login-text-field">
              <TextField
                id="email"
                label="Email"
                type="email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                fullWidth
                required
                InputLabelProps={{
                  className: classes.label
                }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </div>

            <div className="login-text-field">
              <TextField
                id="password"
                label="Password"
                type="password"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
                fullWidth
                autoComplete="on"
                required
                InputLabelProps={{
                  className: classes.label
                }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </div>

            <div className="login-button">
              <LoginButton>Login</LoginButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
