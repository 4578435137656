import sintefGreyImg from "../../assets/images/partners/grey/sintef.png";
import sintefColorImg from "../../assets/images/partners/color/sintef.png";

import sapienzaGreyImg from "../../assets/images/partners/grey/sapienza.png";
import sapienzaColorImg from "../../assets/images/partners/color/sapienza.png";

import uniklaGreyImg from "../../assets/images/partners/grey/unikla.png";
import uniklaColorImg from "../../assets/images/partners/color/unikla.png";

import kthGreyImg from "../../assets/images/partners/grey/kth.png";
import kthColorImg from "../../assets/images/partners/color/kth.png";

import iexecGreyImg from "../../assets/images/partners/grey/iexec.png";
import iexecColorImg from "../../assets/images/partners/color/iexec.png";

import ubitechGreyImg from "../../assets/images/partners/grey/ubitech.png";
import ubitechColorImg from "../../assets/images/partners/color/ubitech.png";

import jotGreyImg from "../../assets/images/partners/grey/jot.png";
import jotColorImg from "../../assets/images/partners/color/jot.png";

import mogGreyImg from "../../assets/images/partners/grey/mog.png";
import mogColorImg from "../../assets/images/partners/color/mog.png";

import catalanoGreyImg from "../../assets/images/partners/grey/catalano.png";
import catalanoColorImg from "../../assets/images/partners/color/catalano.png";

import telluGreyImg from "../../assets/images/partners/grey/tell_u.png";
import telluColorImg from "../../assets/images/partners/color/tell_u.png";

import boschGreyImg from "../../assets/images/partners/grey/bosch.png";
import boschColorImg from "../../assets/images/partners/color/bosch.png";

const ListPartners = [
  {
    name: "sintef",
    description: "SINTEF AS",
    color: "#033265",
    country: "Norway", /* Norway */
    coordinates: [10, 60],
    greyImg: sintefGreyImg,
    colorImg: sintefColorImg,
    link: "/consortium/sintef"
  },
  {
    name: "sapienza",
    description: "SAPIENZA Univ. Rome",
    color: "#831429",
    country: "Italy", /* Italy */
    coordinates: [13, 42],
    greyImg: sapienzaGreyImg,
    colorImg: sapienzaColorImg,
    link: "/consortium/sapienza"
  },
  {
    name: "unikla",
    description: "Univ. Klagenfurt",
    color: "#57A0C7",
    country: "Austria",
    coordinates: [15, 48.3],
    greyImg: uniklaGreyImg,
    colorImg: uniklaColorImg,
    link: "/consortium/universitat-klagenfurt"
  },
  {
    name: "kth",
    description: "Royal Inst. of Technology",
    color: "#385086",
    country: "Sweden",
    coordinates: [14.5, 58],
    greyImg: kthGreyImg,
    colorImg: kthColorImg,
    link: "/consortium/kth"
  },
  {
    name: "iexec",
    description: "iExec",
    color: "#F5C902",
    country: "France",
    coordinates: [2.5, 46],
    greyImg: iexecGreyImg,
    colorImg: iexecColorImg,
    link: "/consortium/iexec"
  },
  {
    name: "ubitech",
    description: "UBITECH",
    color: "#00ADEE",
    country: "Greece",
    coordinates: [22, 39],
    greyImg: ubitechGreyImg,
    colorImg: ubitechColorImg,
    link: "/consortium/ubitech"
  },
  {
    name: "jot",
    description: "JOT Internet Media",
    color: "#9A1A7D",
    country: "Spain",
    coordinates: [-3, 41],
    greyImg: jotGreyImg,
    colorImg: jotColorImg,
    link: "/consortium/jot-im"
  },
  {
    name: "mog",
    description: "MOG Technologies",
    country: "Portugal",
    color: "#48BDEA",
    coordinates: [-8, 39.5],
    greyImg: mogGreyImg,
    colorImg: mogColorImg,
    link: "/consortium/mog-technologies"
  },
  {
    name: "catalano",
    description: "Catalano",
    color: "#084131",
    country: "", /* Italy */
    coordinates: [11, 44],
    greyImg: catalanoGreyImg,
    colorImg: catalanoColorImg,
    link: "/consortium/catalano"
  },
  {
    name: "tellu",
    description: "Tellu",
    color: "#057ECA",
    country: "", /* Norway */
    coordinates: [8, 62],
    greyImg: telluGreyImg,
    colorImg: telluColorImg,
    link: "/consortium/tellu"
  },
  {
    name: "bosch",
    description: "BOSCH",
    color: "#ED1B24",
    country: "Germany",
    coordinates: [10, 50.5],
    greyImg: boschGreyImg,
    colorImg: boschColorImg,
    link: "/consortium/bosch"
  }
];

export default ListPartners;