import React, { Component } from "react";

import cloudImg from "../../assets/images/image_cloud.jpg";
import dataImg from "../../assets/images/image_data.jpg";
import toolboxImg from "../../assets/images/image_toolbox.jpg";

import MobileCarouselElem from "../../templates/mobilecarousel/MobileCarousel";
import CarouselElem from "../../templates/carousel/Carousel";

const CarouselData = [
  {
    id: 0,
    header: "About the project",
    body:
      "DataCloud provides a novel paradigm for Big Data pipelines, covering the entire lifecycle of managing Big data pipelines through discovery, design, simulation, provisioning, deployment, and adaptation across the Computing Continuum.",
    btnText: "See more about the project",
    link: "/project/about-the-project",
    img: cloudImg,
  },
  {
    id: 1,
    header: "Toolbox",
    body:
      "The project delivers its core results via a set of interoperable tools that form the DataCloud Toolbox.",
    btnText: "See our toolbox",
    link: "/project/toolbox",
    img: toolboxImg,
  },
  {
    id: 2,
    header: "Business cases",
    body:
      "The DataCloud Toolbox is used in the development of five diverse business cases to demonstrate its practical applicability.",
    btnText: "See our business cases",
    link: "/project/business-cases",
    img: dataImg,
  },
];

export default class HomeCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { responsiveView: false, loading: false };

    this.checkScreenSize = this.checkScreenSize.bind(this);
  }

  checkScreenSize = () => {
    let screenSize = window.innerWidth;

    if (screenSize <= 1050) {
      this.setState({ responsiveView: true });
    } else {
      this.setState({ responsiveView: false });
    }

    this.setState({ loading: true });
  };

  componentDidMount() {
    this.checkScreenSize();

    window.addEventListener("resize", this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          this.state.responsiveView ? (
            <div className="mobile-carousel">
            <MobileCarouselElem data={CarouselData} />
            </div>
          ) : (
            <CarouselElem data={CarouselData} />
          )
        ) : null}
      </div>
    );
  }
}
