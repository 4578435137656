import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import MetaTags from "../../../components/metatags/MetaTags";
import PageHeader from "../../../components/pageheader/PageHeader";
import headerImg from "../../../assets/images/header_project.jpg";
import BusinessCaseItem from "./BusinessCaseItem";

import smarkImg from "../../../assets/images/pj_uc_smark.png";
import mogsportsImg from "../../../assets/images/pj_uc_mogsports.png";
import tluhealthImg from "../../../assets/images/pj_uc_tluhealth.png";
import pdiceImg from "../../../assets/images/pj_uc_pdice.png";
import amansImg from "../../../assets/images/pj_uc_amans.png";

import "./BusinessCases.css";

export default class BusinessCases extends Component {
  constructor(props) {
    super(props);
    this.state = { right: true };

    this.checkScreenSize = this.checkScreenSize.bind(this);
  }

  checkScreenSize = () => {
    let screenSize = window.innerWidth;

    if (screenSize <= 960) {
      this.setState({ right: false });
    } else {
      this.setState({ right: true });
    }
  };

  componentDidMount() {
    this.checkScreenSize();

    window.addEventListener("resize", this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  }

  render() {
    return (
      <div>
        <MetaTags title="Business Cases | Project | DataCloud Project" />

        <PageHeader img={headerImg}>BUSINESS CASES</PageHeader>

        <div className="business-cases-wrapper">
          <div className="business-cases-project">
            <Grid className="project-grid" container align="stretch">
              <Grid item>
                <BusinessCaseItem
                  alignRight={false}
                  title="Smart Mobile Marketing Campaigns (SMARK)"
                  imgSrc={smarkImg}
                  imgAlt="SMARK Use Case"
                >
                  The main objective of this business case is to develop a new
                  product for massive data-driven management of marketing
                  campaigns enabling companies to increase their mobile-related
                  business gross margin through cost-effective Cloud-based Big
                  Data processing, more accurate prediction of impress keywords
                  with increased revenue-per-click rate.
                </BusinessCaseItem>
              </Grid>

              <Grid item>
                <BusinessCaseItem
                  alignRight={this.state.right}
                  title="Automatic Live Sports Content Annotation (MOGSPORTS)"
                  imgSrc={mogsportsImg}
                  imgAlt="MOGSPORTS Use Case"
                >
                  The main objective of this business case is to develop a new
                  product to lower the production costs and enhance audience engagement
                  and experience in decentralised crowdsourced live sport
                  broadcasting by automatically annotating large quantities of
                  media data from various sources combined with AI algorithms
                  for adaptive monitoring and runtime operation.
                </BusinessCaseItem>
              </Grid>

              <Grid item>
                <BusinessCaseItem
                  alignRight={false}
                  title="Digital Health System (TLUHEALTH)"
                  imgSrc={tluhealthImg}
                  imgAlt="TLUHEALTH Use Case"
                >
                  The main objective of this business case is to develop a new
                  product for provisioning of a scalable digital health system
                  to support and help home patients, especially elderly stay
                  during treatment and care with ensured data privacy and trust.
                </BusinessCaseItem>
              </Grid>

              <Grid item>
                <BusinessCaseItem
                  alignRight={this.state.right}
                  title="Products Development in Ceramic Engineering (P-DICE)"
                  imgSrc={pdiceImg}
                  imgAlt="P-DICE Use Case"
                >
                  The main objective of this business case is to develop a new
                  product providing a platform that enables smart adaptation of
                  manufacturing sanitary systems to mitigate the effects of
                  ceramic deformations by data sharing throughout the ceramics
                  plant for complete production lines and energy conservation
                  and output optimization.
                </BusinessCaseItem>
              </Grid>

              <Grid item>
                <BusinessCaseItem
                  alignRight={false}
                  title="Analytics of Manufacturing Assets (AMANS)"
                  imgSrc={amansImg}
                  imgAlt="AMANS Use Case"
                >
                  The main objective of this business case is to develop a new
                  product to enhance factory automation through cost-effective
                  provisioning of monitoring and diagnostics applications that
                  rely on automated Big Data pipelines discovery and their
                  deployment over data generated by or relevant to manufacturing
                  assets that span across an entire factory.
                </BusinessCaseItem>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
