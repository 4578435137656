import React, { Component } from "react";

import MobileDropdown from "./MobileDropdown";

const items = [
  { link: "/resources/deliverables", dropdownTitle: "Deliverables" },
  { link: "/resources/journal-articles", dropdownTitle: "Journal Articles" },
  { link: "/resources/conference-papers", dropdownTitle: "Conference Papers" },
  { link: "/resources/white-papers", dropdownTitle: "White Papers" },
  { link: "/resources/presentation-slides", dropdownTitle: "Presentation Slides" },
  { externalLink: "https://datacloud-project.github.io/toolbox", dropdownTitle: "Software" },
  { link: "/resources/others", dropdownTitle: "Others" },
];

export default class ResourcesDropdown extends Component {
  
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/resources")) {
      return true;
    }

    return false;
  };

  handleClose() {
    this.props.handleClose();
  }

  render() {
    return (
      <MobileDropdown
        matchResourcesPages={this.matchResourcesPages.bind(this)}
        title="Resources"
        items={items}
        closeDrawer={this.handleClose.bind(this)}
      ></MobileDropdown>
    );
  }
}
