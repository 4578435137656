import React, {Component} from "react";
import ReactMarkdown from "react-markdown";

import Header from "../../components/individualheader/Header";
import MetaTags from "../../components/metatags/MetaTags";
import Gallery from "../../templates/gallery/Gallery";

import newsImg from "../../assets/images/whatsnew-news.jpg";
import eventsImg from "../../assets/images/whatsnew-events.jpg";
import blogImg from "../../assets/images/whatsnew-blog.jpg";

import "./IndividualNew.css";

import getNews from "../../utils/api/news-get";
import getEvents from "../../utils/api/events-get";
import getBlogPosts from "../../utils/api/blog-get";

export default class IndividualNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      imgHeader: "",
      img: [],
      video: [],
      file: [],
      loading: false,
    };

    this.convertDate = this.convertDate.bind(this);
    this.convertTime = this.convertTime.bind(this);
    this.removeLastUrlDirectory = this.removeLastUrlDirectory.bind(this);
  }

  async componentDidMount() {
    let blogPosts = await getBlogPosts.getBlogPosts();
    let events = await getEvents.getEvents();
    let newsPosts = await getNews.getNews();

    // Merge 3 arrays(blogPosts, events, newsPosts) into one(items)
    let items = [];
    items.push(...blogPosts, ...events, ...newsPosts);

    //checks if component id match id in params
    for (var i = 0; i < items.length; i++) {

      const convertedTitle = items[i].title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[-]+/g, "-")
        .replace(/[^\w-]+/g, "");

      if (convertedTitle === this.props.match.params.title) {
        let imgHeader = "";

        if (items[i].type === "news") {
          imgHeader = newsImg;
        } else if (items[i].type === "events") {
          imgHeader = eventsImg;
        } else if (items[i].type === "blog") {
          imgHeader = blogImg;
        }

        this.setState({
          news: items[i],
          imgHeader: imgHeader,
          img: items[i].image,
          video: items[i].video,
          file: items[i].file,
        });
      }
    }

    this.setState({loading: true});
  }

  convertDate(date) {
    var options = {year: "numeric", month: "long", day: "numeric"};
    return new Date(date).toLocaleDateString([], options);
  }

  convertTime(time) {
    return new Date(time).toLocaleTimeString();
  }

  //Removes id from url
  removeLastUrlDirectory() {
    var path = window.location.pathname.split("/");
    path.pop();
    return path.join("/");
  }

  render() {
    return (
      <>
        <MetaTags
          title={this.state.news?.title}
          description={this.state.news?.content}
        />
        {/* News Page header image and title */}
        <Header
          img={this.state.imgHeader}
          title={this.state.news.title}
          type={this.state.news.type}
          location={this.state.news.location}
          date={this.convertDate(this.state.news.date)}
          time={this.convertTime(this.state.news.date)}
        ></Header>

        {/* Content Page Wrapper => Defines background and top/bottom padding */}
        <div className="content-page-wrapper">
          {/* Content Page Content => Centered div containing news content */}
          <div className="content-page-section">
            <div className="content">
              {/* <div>{this.state.news.location}</div>
              <div>{this.state.news.date}</div> */}
              <ReactMarkdown source={this.state.news.content}></ReactMarkdown>

              {Object.keys(this.state.file).length !== 0 ? (
                <div className="file-section">
                  <div className="file-text">File available here: </div>
                  <div className="file-text">
                    {this.state.file.map((files) => {
                      return (
                        <a
                          key={files.id}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={window.$apiUrl + files.url}
                          className="file-link"
                        >
                          {files.name}
                        </a>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.state.news.link ? (
                <div className="link-section">
                  <div className="link-text">
                    For more information visit this link:{" "}
                  </div>
                  <div className="link-text">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.news.link}
                      className="link"
                    >
                      {this.state.news.link}
                    </a>
                  </div>
                </div>
              ) : null}

              {this.state.loading === true ? <Gallery {...this.state} /> : null}

              {this.state.news.author ? (
                <div className="author">
                  <div className="author-text">
                    This article was written by &nbsp;
                  </div>
                  <div className="author-name">{this.state.news.author}</div>
                </div>
              ) : null}

              <div className="published">
                <div className="published-text">Published in &nbsp;</div>
                <div className="published-date">
                  {this.convertDate(this.state.news.published)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
