import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import MetaTags from "../../components/metatags/MetaTags";
import PageHeader from "../../components/pageheader/PageHeader";
import Objectives from "./objectives/Objectives";

import headerImg from "../../assets/images/header_project_resize.jpg";

import "./Project.css";

export default class Project extends Component {
  render() {
    return (
      <div>
        <MetaTags title="About the Project | Project | DataCloud Project" />

        <PageHeader img={headerImg}>
          ABOUT THE PROJECT
        </PageHeader>

        <div className="about-project">
          <div className="text-item">
            DataCloud delivers a <b>toolbox</b> of new languages, methods,
            infrastructures, and prototypes for <b>discovering, simulating,
            deploying, and adapting Big Data pipelines on heterogeneous and
            untrusted resources</b>. DataCloud separates the design from the
            run-time aspects of Big Data pipeline deployment, empowering domain
            experts to take an active part in their definitions.
            <br />
            <br />
            Its aim is to lower the technological entry barriers for the
            incorporation of Big Data pipelines in organizations’ business
            processes and make them accessible to a wider set of stakeholders
            regardless of the hardware infrastructure. DataCloud validates its
            plan through a strong selection of complementary business cases
            offered by SMEs and a large company targeting higher mobile business
            revenues in smart marketing campaigns, reduced production costs of
            sport events, trustworthy eHealth patient data management, and
            reduced time to production and better analytics in Industry 4.0
            manufacturing.
            <br />
            <br />
            The{" "}
            <Link
              variant="inherit"
              color="primary"
              underline="always"
              component={NavLink}
              to="/consortium/who-we-are"
            >
              <b>consortium</b>
            </Link>{" "}
            consists of 11 partners from eight countries. It has three strong
            university partners specialized in Big Data, distributed computing,
            and high-productivity languages, led by a research institute.
            DataCloud gathers six SMEs and one large company (as technology
            providers and stakeholders / users / early adopters) that prioritize the
            business focus of the project in achieving high business impacts.
          </div>
        </div>

        <Objectives />
      </div>
    );
  }
}
