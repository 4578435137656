import React, { Component } from "react";
import { Marker } from "react-simple-maps";

export default class MapMaker extends Component {
  constructor(props) {
    super(props);
    this.handleMarkerChange = this.handleMarkerChange.bind(this);
  }

  handleMarkerChange() {
    this.props.handleMarkerChange(this.props.name);
  }

  render() {
    let isMarkerSelected = (this.props.selectedMarker === this.props.name);
    let isImageSelected = (this.props.selectedImage === this.props.name);
    
    let isSelected = (isMarkerSelected || isImageSelected);

    return (
      <Marker key={this.props.name} coordinates={this.props.coordinates}>
        <g
          className="partner-marker"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-15, -30), scale(1.2)"
          onMouseEnter={() => this.props.handleMarkerChange(this.props.name)}
          onMouseLeave={() => this.props.handleMarkerChange("")}
        >
          <path
            fill={isSelected ? this.props.color : "black" }
            transform="scale(1,1.2)"
            d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z"
          />
          <circle fill="white" cx="12" cy="11" r="3" />
        </g>

        <text
          className="country-description"
          textAnchor="middle"
          y="12"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "bolder",
            fill: "#5D5A6D",
            fontSize: 11
          }}
        >
          {this.props.country}
        </text>
      </Marker>
    );
  }
};