import React, { Component } from "react";
import { Container } from "@material-ui/core";

import backgroundImg from "../../assets/images/background_img_resize.jpg";

import "./HomeBanner.css";

export default class HomeLayout extends Component {

	render() {
		return (
      <section className="home-section">
        <Container className="home-container">
          <div className="home-backdrop" />
          <div className="home-background" style={{backgroundImage: `url("${backgroundImg}")`}} />

          <h1 className="home-header-text">
            Enabling the big data pipeline <br className="home-break"/>lifecycle on the computing continuum
					</h1>
          
          <div className="ball-wrapper">
            <div className="discover-ball"/>
          </div>
          <div className="discover-line"/>

        </Container>
      </section>
		);
	}
}