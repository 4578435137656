import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import DropdownItem from "./DropdownItem"

import ListPartners from "../../pages/partners/ListPartners";

export default class ConsortiumDropdown extends Component {
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/consortium")) {
      return true;
    }

    return false;
  };

  render() {
    return (
      <div className="dropdown">
        <Link
          className="dropbtn main-link"
          variant="inherit"
          color="inherit"
          underline="none"
          component={NavLink}
          activeClassName="selected"
          isActive={this.matchResourcesPages.bind(this)}
          exact={true}
          to="#"
        >
          Consortium
        </Link>

        <div className="dropdown-content">
          <div className="dropdown-placeholder" />

          <div className="dropdown-links">
            
          <DropdownItem link="/consortium/who-we-are">Who we are</DropdownItem>

            {ListPartners.map((partner) => {
              return <DropdownItem key={partner.name} link={partner.link}>{partner.description}</DropdownItem>
            })}
          
          </div>
        </div>
      </div>
    );
  }
}
