import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import CardItem from "../../components/carditem/CardItem";

import getBlogPosts from "../../utils/api/blog-get";
import getEvents from "../../utils/api/events-get";
import getNews from "../../utils/api/news-get";

export default class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };

    this.convertDate = this.convertDate.bind(this);
    this.convertTitle = this.convertTitle.bind(this);
    this.checkScreenSize = this.checkScreenSize.bind(this);
  }

  async componentDidMount() {
    let blogPosts = await getBlogPosts.getBlogPosts();
    let events = await getEvents.getEvents();
    let newsPosts = await getNews.getNews();

    // Merge 3 arrays(blogPosts, events, newsPosts) into one(items)
    let items = [];
    items.push(...blogPosts, ...events, ...newsPosts);

    // Sort array by recent publication date
    let sortedItems = items.sort(function (a, b) {
      var dateA = new Date(a.published),
        dateB = new Date(b.published);
      return dateB - dateA;
    });

    this.setState({
      items: sortedItems,
    });

    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  }

  convertDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  convertTitle(title) {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[-]+/g, "-")
      .replace(/[^\w-]+/g, "");
  }

  checkScreenSize() {
    let screenSize = window.innerWidth;

    if (screenSize >= 1824) {
      this.setState({ numCards: 4 });
    } else if (screenSize <= 1824 && screenSize >= 1280) {
      this.setState({ numCards: 3 });
    } else {
      this.setState({ numCards: 2 });
    }

    // console.log(this.state.numCards)
  }

  render() {
    return (
      <>
        <Grid>
          {this.state.items.slice(0, this.state.numCards).map((items) => {
            return (
              <Grid
                item
                key={items.id}
                style={{ padding: "40px 20px", display: "inline-flex" }}
              >
                <CardItem
                  urlTitle={this.convertTitle(items.title)}
                  imgSrc={items.image.slice(0, 1).map((media) => {
                    return window.$apiUrl + media.url;
                  })}
                  imgAlt={items.image.slice(0, 1).map((media) => {
                    return media.alternativeText;
                  })}
                  type={items.type}
                  title={items.title}
                  footer={this.convertDate(items.published)}
                ></CardItem>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
}
