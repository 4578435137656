import React, { Component } from "react";

import "./PageHeader.css";

export default class PageHeader extends Component {
  render() {
    return (
      <div className="header-section">
        <div className="header-backdrop" />
        <div className="header-background" style={{ backgroundImage: `url("${this.props.img}")` }} />

        <h1 className="header-text"> {this.props.children}</h1>

          {/* <svg className="header-svg" height="70" viewBox={this.props.viewBox} >
            <text
              className="header-text"
              // fontFamily="Big John Regular"
              // fill="transparent"
              // stroke="white"
              // strokeWidth="1"
              // fontSize="80"
              // letterSpacing="16"
              // textAnchor="left"
              x="0"
              y="65"
            >
              {this.props.children}
            </text>
          </svg> */}
        </div>
    );
  }
}
