import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, IconButton, Button, Tooltip } from "@material-ui/core";
import { ICONS_TOOLTIP, CustomFilterButton } from "./CustomIcons";

const useStyles = makeStyles({
  primary: {
    backgroundColor: "#00406E",
    borderRadius: "19px",
    color: "#FFFFFF",
    height: 42,
    padding: "0 30px",
    marginTop: 3,
    marginRight: 8,
    "&:hover": {
      backgroundColor: "#00406E",
      opacity: 0.8,
    }
  },
  secondary: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    borderRadius: "19px",
    color: "#FFFFFF",
    height: 42,
    padding: "0 30px",
    marginTop: 3,
    marginRight: 8,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      opacity: 0.8,
    }
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  disabled: {
    color: "rgba(255, 255, 255, 0.6) !important",
    backgroundColor: "rgba(0, 64, 110, 0.6)"
  }
});


export default function TableAction(props) {
  const classes = useStyles();

  let action = props.action;
  const disabled = action.disabled || props.disabled;

  if (typeof action === "function") {
    action = action(props.data);
    if (!action) {
      return null;
    }
  }

  const handleOnClick = event => {
    if (action.onClick) {
      action.onClick(event, props.data);
      event.stopPropagation();
    }
  };

  const icon =
  typeof action.icon === "string" ? (
    <Icon {...action.iconProps}>{action.icon}</Icon>
  ) : typeof action.icon === "function" ? (
    action.icon({ ...action.iconProps, disabled: disabled })
  ) : (
    <action.icon />
  );

  const getTextButton = (addButton) => {
    const textButton = (
      <span>
        <Button
          classes={{
            root: (addButton ? classes.primary : classes.secondary),
            label: classes.label,
            disabled: classes.disabled
          }}
          color="inherit"
          disabled={action.disabled}
          onClick={(event) => handleOnClick(event)}
        >
          {addButton ? icon : <CustomFilterButton/>}
        </Button>
      </span>
    );
  
    return textButton;
  }

  const getIconButton = () => {
    const iconButton = (
      <span>
        <IconButton
          size={props.size}
          color="inherit"
          disabled={action.disabled}
          onClick={(event) => handleOnClick(event)}
        >
          {icon}
        </IconButton>
      </span>
    );

    return iconButton;
  }

  let button;
  if (props.action.tooltip === ICONS_TOOLTIP.ADD) {
    button = getTextButton(true);
  } else if (props.action.tooltip === ICONS_TOOLTIP.FILTER) {
    button = getTextButton(false);
  } else {
    button = getIconButton();
  }

  if (action.tooltip) {
    return <Tooltip title={action.tooltip}>{button}</Tooltip>;
  } else {
    return button;
  }
}