import React from "react";

import PageHeader from "../../components/pageheader/PageHeader";
import MetaTags from "../../components/metatags/MetaTags";
import Cards from "./Cards";
import Form from "./Form";

import contactsImg from "../../assets/images/header_contacts_resize.jpg";
import contactsBkg from "../../assets/images/contacts_bkg.png";

import "./Contacts.css";

export default function Contacts() {
  
  return (
    <div className="contacts-page">
      <MetaTags title="Contacts | DataCloud Project" />
      <PageHeader img={contactsImg} width={600}>
        CONTACTS
      </PageHeader>

      <Cards />

      <div className="contacts-body">
        <div className="contacts-bkg">
          <img src={contactsBkg} alt="Contacts Background" />
        </div>

        <Form />
      </div>
      

    </div>
  );
}