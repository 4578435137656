import React, { Component } from "react";
import { Carousel } from "antd";

import MobileCarouselPanel from "./MobileCarouselPanel";
import "./MobileCarousel.css";

export default class MobileCarouselElem extends Component {
  render() {
    return (
      <Carousel
        className="wrapper" 
        dotPosition="bottom" 
        autoplay={false} 
        vertical={false}
        draggable={true} 
        swipeToSlide={true}
        verticalSwiping={false}
      >
        {this.props.data.map((value, index) => {
          return <MobileCarouselPanel key={index} data={value} />
        })}
      </Carousel>
    );
  }
};