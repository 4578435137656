import React, { Component } from "react";

import MainFooter from "./MainFooter";
import SecondaryFooter from "./SecondaryFooter";

import "./Footer.css";

export default class Footer extends Component {

	render() {
		return (
      <>
        <MainFooter />
        <SecondaryFooter />
      </>
		);
	}
}