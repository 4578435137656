const getResources = {
    getResources() {
      return fetch(`${window.$apiUrl}/resources?_limit=-1`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonReponse) => {
          return jsonReponse.map((resources) => {
            return {
              id: resources.id,
              title: resources.Title,
              abstract: resources.Abstract,
              image: resources.Images,
              video: resources.Video,
              file: resources.Files,
              author: resources.Author,
              link: resources.Link,
              type: resources.Type,
              published: resources.published_at,
            };
          });
        });
    },
  };
  
  export default getResources;
  