const getBlogPosts = {
  getBlogPosts() {
    return fetch( `${window.$apiUrl}/blog-posts?_sort=published_at:DESC&_limit=-1`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonReponse) => {
        return jsonReponse.map((blogPosts) => {
          return {
            id: blogPosts.id,
            title: blogPosts.Title,
            content: blogPosts.Content,
            image: blogPosts.Images,
            video: blogPosts.Video,
            file: blogPosts.Files,
            author: blogPosts.Author,
            link: blogPosts.Link,
            published: blogPosts.published_at,
            type: "blog",
          };
        });
      });
  },
};

export default getBlogPosts;
