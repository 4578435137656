import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class KTH extends Component {
  render() {
    return (
      <div>
        <MetaTags title = "KTH | Consortium | DataCloud Project" />
        <PartnersHeader
          title="KTH Royal Institute of Technology"
          color="#385086"
        ></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              KTH Royal Institute of Technology was founded in 1827 and it has
              over 1600 active postgraduate, 12000 undergraduate students and
              about 3000 employees. <br />
              <br />
              Research orientation is a major profile of KTH education (it is
              responsible for about one-third of Swedish engineering and
              technical research at post-secondary level). <br />
              <br />
              The Unit of Software and Computer Systems (SCS) as a part of
              Computer Science department of the School of Electrical
              Engineering and Computer Science is located in Kista that is the
              main Swedish resource center for Information Technology, so
              called, "Scandinavian Silicon Valley". The SCS unit has 4
              professors, 5 associate professors and 10 PhD students. SCS
              focuses on education and research in the area of the design and
              use of systems in its widest sense, including: software
              engineering, distributed systems, service computing, simulation
              and modelling, constraint programming etc. Members of the SCS are
              involved into a large number of EU and national research projects.
              Most of work for PhD and master levels is carried in the
              frameworks of the research projects. About half of research
              activity in the Department is financed from external sources.
            </div>
            <div className="partner-button">
              <a href="https://www.kth.se/en" target="_blank" rel="noreferrer">
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              KTH will lead the DSL-related research in
              DataCloud and contribute with their expertise to discovery and
              simulation of Big Data pipelines.
              <br />
              <br /> KTH will lead the development of DEF-PIPE and contribute to
              DIS-PIPE and SIM-PIPE
            </div>
          </div>
        </div>
      </div>
    );
  }
}
