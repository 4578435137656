import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F6F6F6",
  },
});

export default function ToolboxItem(props) {
  const classes = useStyles();

  return (
    <Card
      classes={{
        root: classes.root,
      }}
      elevation={1}
      square={true}
      className="toolbox-item"
    >
      <div className="toolbox-item-img">
        <img src={props.imgSrc} alt=""></img>
      </div>
      <div className="toolbox-item-content">
        <h3 className="toolbox-title">{props.title}</h3>
        <p>{props.children}</p>
      </div>
    </Card>
  );
}
