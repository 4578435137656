import React, { useState } from "react";
import { Link, Collapse, } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import DropdownItem from "../navbar/DropdownItem";

//ICONS
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import "../navbar/Dropdown.css";

export default function MobileDropdown(props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const closeDrawer = () => {
    props.closeDrawer();
  }

  return (
    <div className="mobile-dropdown">
      <Link
        className="dropbtn main-link"
        variant="inherit"
        color="inherit"
        underline="none"
        component={NavLink}
        activeClassName="selected"
        isActive={props.matchResourcesPages}
        exact={true}
        to="#"
        onClick={handleClick}
      >
        {props.title}

        {open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
      </Link>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {(props.items).map((item, i) => {
          return <div key={i} onClick={closeDrawer}>
            <DropdownItem  className="dropdown-item" link={item.link} externalLink={item.externalLink} >
              {item.dropdownTitle}
            </DropdownItem>
          </div>
        })}
        
      </Collapse>
    </div>
  );
}
