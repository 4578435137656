import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class SINTEF extends Component {
  render() {
    return (
      <div>
        <MetaTags title = "SINTEF AS | Consortium | DataCloud Project" />
        <PartnersHeader title="SINTEF AS" color="#033265"></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              SINTEF AS is Scandinavia's largest independent research
              organisation and is a non-profit research foundation. We employ
              2100 people most of whom are located in Trondheim and Oslo
              (Norway). More than 90% of our annual turnover derives from
              contract research for industry and the public sector in Norway and
              internationally, and we receive minimal state funding (around 6%).
              <br />
              <br />
              SINTEF is multidisciplinary, with international top-level
              expertise in a wide range of technological and scientific
              disciplines, medicine and the social sciences. Our company vision
              is "technology for a better society", and this is an important
              aspect of our societal role to contribute to the creation of more
              jobs. We act as an incubator, commercialising technologies through
              the establishment of new companies. <br />
              <br />
              SINTEF is represented in this consortium by SINTEF DIGITAL through
              the department for Software and Service Innovation (SSI).
            </div>
            <div className="partner-button">
              <a
                href="https://www.sintef.no/en/"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              SINTEF will act as the project coordinator (both management and
              technical) of DataCloud and serves as a research and technology
              provider in the project. Its technical focus
              will be on the support for defining the simulation system of
              DataCloud, contributing to the solutions for discovery and
              definition of Big Data pipelines.
              <br />
              <br /> SINTEF will build upon and extend research results from
              previous projects in the area of Cloud, IoT, and Big Data, such as
              euBusinessGraph, EW-Shopp, TheyBuyForYou, Energytics, and
              EarlyWarn. SINTEF will lead the development of SIM-PIPE and
              contribute to DIS-PIPE and DEF-PIPE.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
