import React, { Component } from "react";

import europeFlag from "../../assets/images/Flag_of_Europe.svg";

export default class Footer extends Component {

	render() {
		return (
      <div className="secondary-footer">
        <img className="footer-europe" src={europeFlag} alt="Europe Flag"/>

        This project has received funding from the European Union's Horizon 2020 research and 
        innovation programme under grant agreement No 101016835
      </div>
		);
	}
}