import React, { Component } from "react";

import MobileDropdown from "./MobileDropdown";

const items = [
  { link: "/whats-new/news", dropdownTitle: "News" },
  { link: "/whats-new/events", dropdownTitle: "Events" },
  { link: "/whats-new/blog", dropdownTitle: "Blog" },
];

export default class NewsDropdown extends Component {

  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/whats-new")) {
      return true;
    }

    return false;
  };

  handleClose() {
    this.props.handleClose();
  }

  render() {
    return (
      <MobileDropdown
        matchResourcesPages={this.matchResourcesPages.bind(this)}
        title="What's New"
        items={items}
        closeDrawer={this.handleClose.bind(this)}
      ></MobileDropdown>
    );
  }
}
