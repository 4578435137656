import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import ToolboxItem from "./ToolboxItem";

import MetaTags from "../../../components/metatags/MetaTags";
import PageHeader from "../../../components/pageheader/PageHeader";
import headerImg from "../../../assets/images/header_project.jpg";

import GitHubIcon from "@material-ui/icons/GitHub";

import toolbox1 from "../../../assets/images/algorithm.jpg";
import toolbox2 from "../../../assets/images/dsl.jpg";
import toolbox3 from "../../../assets/images/toolbox_3.jpg";
import toolbox4 from "../../../assets/images/blockchain.jpg";
import toolbox5 from "../../../assets/images/servers.jpg";
import toolbox6 from "../../../assets/images/code.jpg";

import "./Toolbox.css";

export default class Toolbox extends Component {
  render() {
    return (
      <div>
        <MetaTags title="Toolbox | Project | DataCloud Project" />
        <PageHeader img={headerImg}>Toolbox</PageHeader>

        <div className="toolbox-wrapper">
          <div className="toolbox-project">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={6}>
                <div className="toolbox-item">
                  <ToolboxItem title="DIS-PIPE" imgSrc={toolbox1}>
                    DIS-PIPE provides scalable integration of process mining
                    techniques and AI algorithms to learn the structure of Big
                    Data pipelines by extracting, processing and interpreting
                    huge amounts of event data collected from several
                    heterogeneous data sources. Furthermore, the tool includes a
                    graphical interface that supports a wide variety of
                    analytics techniques for visualizing the discovered
                    pipelines together with detailed diagnostics information
                    about their execution.
                  </ToolboxItem>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="toolbox-item">
                  <ToolboxItem title="DEF-PIPE" imgSrc={toolbox2}>
                    DEF-PIPE provides a visual design for implementation of Big
                    Data pipelines based on a DSL, including means to store and
                    load the pipeline definitions and to display them in a user
                    interface where domain experts declare the pipeline
                    structure. Furthermore, it enables Data Scientists to define
                    the content by configuring each individual step and
                    injecting code or customizing generic pre-defined step
                    templates.
                  </ToolboxItem>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="toolbox-item">
                  <ToolboxItem title="SIM-PIPE" imgSrc={toolbox3}>
                    SIM-PIPE generates and simulates a deployment configuration
                    for the final deployment that conforms to the hardware
                    requirements and includes any additional necessary
                    middleware inter-step communication code. Finally, the tool
                    provides a pipeline testing functionality, including a
                    sandbox for evaluating individual pipeline step performance,
                    and a simulator to determine the performance of the overall
                    Big Data pipeline.
                  </ToolboxItem>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="toolbox-item">
                  <ToolboxItem title="R-MARKET" imgSrc={toolbox4}>
                    R-MARKET deploys a decentralized backbone resource network,
                    based on a hybrid permissioned and permissionless blockchain
                    that federates a vast set of heterogeneous resources from
                    various providers spread across the Computing Continuum
                    under a single umbrella. R-MARKET creates a marketplace for
                    resources, provided at each service layer and managed in a
                    democratic manner, which increases the overall trust. The
                    marketplace enables transparent provisioning of resources
                    over multiple control and network domains facilitating
                    external use.
                  </ToolboxItem>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="toolbox-item">
                  <ToolboxItem title="DEP-PIPE" imgSrc={toolbox5}>
                    DEP-PIPE, addressing run-time aspects, provides a tool for
                    elastic and scalable deployment and orchestration of Big
                    Data pipelines over the Computing Continuum. The tool allows
                    optimized adaptation of data pipelines with elastic
                    deployment, featuring real-time event detection and
                    automated decision-making for automated deployment and
                    orchestration with increased resilience.
                  </ToolboxItem>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="toolbox-item">
                  <ToolboxItem title="ADA-PIPE" imgSrc={toolbox6}>
                    ADA-PIPE, addressing run-time aspects, provides a data-aware
                    algorithm for smart and adaptable provisioning of resources
                    and services across the Computing Continuum with an
                    infrastructure drift adaptation capability. The tool allows
                    intelligent resource reconfiguration for improved
                    computational performance and interoperability by monitoring
                    and analysis of diverse resources.
                  </ToolboxItem>
                </div>
              </Grid>
            </Grid>
            <div className="toolbox-github">
              <h1 className="github-title">Visit DataCloud GitHub page</h1>
              <a href="https://datacloud-project.github.io/toolbox/" target="_blank" rel="noreferrer">
                <div className="github-text">
                  <div className="github-icon">
                    <GitHubIcon />
                  </div>
                  <div className="github-link">datacloud-project.io</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
