import React from "react";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

const FILTER_ROW_ID = "m--table--filter--row";

const ICONS_TOOLTIP = {
  ADD: "Add",
  FILTER: "Filter by column"
};

const getTableFilterRow = (tableId, filterRowId) => {
  const table = document.querySelector(`#${tableId}`);
  if (!table) { return; }

  const tableHeader = table.querySelector(`#${filterRowId}`);
  return tableHeader;
}

const initializeFilterRow = (tableId, filterRowId) => {
  const tableHeader = getTableFilterRow(tableId, filterRowId);
  if (tableHeader) { tableHeader.style.display = "none"; }
}

const handleFilterClick = (tableId, filterRowId) => {
  const tableHeader = getTableFilterRow(tableId, filterRowId);

  if (tableHeader && tableHeader.style.display === "none") {
    tableHeader.style.display = "table-row";
  } else {
    tableHeader.style.display = "none";
  }
}

const CustomAddButton = () => {
  return (
    <span className="action-text-button">
      <AddIcon/>
      <span className="action-text">ADD NEW</span>
    </span>
  )
}

const CustomFilterButton = () => {
  return (
    <span className="action-text-button">
      <FilterListIcon/>
      <span className="action-text">FILTER</span>
    </span>
  )
}

const CustomExportButton = () => {
  return (
    <span className="action-text-button">
      <SaveAltIcon />
      <span className="action-text">EXPORT</span>
    </span>
  )
}

export { 
  FILTER_ROW_ID, ICONS_TOOLTIP,
  initializeFilterRow, handleFilterClick,
  CustomAddButton, CustomFilterButton, CustomExportButton
};