const postEmail = {
  postEmail(data) {
    return fetch(`${window.$apiUrl}/emails`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonResponse) => {
        return jsonResponse;
      });
  },
};

export default postEmail;
