import React from "react";
import { Grid, Paper } from "@material-ui/core";

export default function BusinessCaseItem(props) {
  let busItemClass = "objectives-item";
  if (props.alignRight) {
    busItemClass += " objective-right";
  }

  let objectiveImg = (
    <Grid item sm={12} md={5} className="business-case-image">
      <img src={props.imgSrc} alt={props.imgAlt} />
    </Grid>
  );

  return (
    <div className="business-case-item">
      <Paper>
        <Grid container className={busItemClass}>
          {!props.alignRight ? objectiveImg : ""}

          <Grid item sm={12} md={7} className="text-section">
            <h2 className="business-case-title">{props.title}</h2>
            <p className="business-case-text">{props.children}</p>
          </Grid>

          {props.alignRight ? objectiveImg : ""}
        </Grid>
      </Paper>
    </div>
  );
}
