import React from "react";
import { Grid } from "@material-ui/core";

import FooterContact from "./FooterContact";
import FooterPartners from "./FooterPartners";

import logo from "../../assets/logo/012_DataCloud.png";

export default function MainFooter() {
  return (
    <div className="main-footer">
      <Grid container spacing={10} justifyContent="center">
        <Grid item xs={12} md={4}>
          <div className="footer-brand">
            <img className="footer-logo" src={logo} alt="DataCloud Logo" />
          </div>

          <FooterContact />
        </Grid>

        <Grid item xs={12} md={4}>
          <h3 className="footer-title">About DataCloud</h3>
          <div className="footer-description">
            <span className="footer-highlight">DataCloud</span> develops novel
            methods to support the complete lifecycle of Big Data pipelines
            processing, enabling their discovery, definition, model-based
            analysis and optimization, simulation, deployment, adaptive run-time
            and monitoring on top of decentralized heterogeneous infrastructures
            on the Computing Continuum.
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <h3 className="footer-title">Partners</h3>

          <FooterPartners />
        </Grid>
      </Grid>
    </div>
  );
}
