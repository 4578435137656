import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class UBITECH extends Component {
  render() {
    return (
      <div>
        <MetaTags title="UBITECH | Consortium | DataCloud Project" />
        <PartnersHeader title="UBITECH" color="#00ADEE"></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              UBITECH is a leading, highly innovative software house, systems
              integrator and technology provider, established to provide leading
              edge intelligent technical solutions and consulting services to
              businesses, organizations and goverment in order to allow the
              efficient and effective secure access and communication with
              various heterogeneous information resources and services, anytime
              and anywhere.
              <br />
              <br /> UBITECH enables real-time valid information processing and
              decision-making, the realization of intelligent business
              environments, and B2B and B2C transactions by providing high
              added-value business-oriented and -based solutions. <br />
              <br />
              UBITECH has been established in Athens, Greece back in 2005,
              concentrated initially in the Greek and Balkan market and
              acquiring several EC and national grants for novel R&D
              initiatives. Currently, UBITECH has extended its operations with
              targeted international activities through its subsidiaries,
              representation offices, business partners and affiliated companies
              in Limassol (Cyprus), Madrid (Spain), Buenos Aires (Argentina) and
              Guayaquil (Ecuador), concentrating mainly in the Spanish-speaking
              countries of Central and Latin America.
            </div>
            <div className="partner-button">
              <a href="https://ubitech.eu/" target="_blank" rel="noreferrer">
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              UBITECH will provide its expertise in resource provisioning,
              orchestration, monitoring and adaptation by leading the creation
              of DEP-PIPE. With previous successful results in the integration
              of EU projects, UBITECH will also lead the DataCloud platform integration
              activities, and ensure the proper cloud to edge
              infrastructure that will be used for the deployment and testing.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
