import React, { useState } from "react";
import { Grid, TextField, IconButton, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import FormButton from "../../components/formbutton/FormButton";

import "./Form.css";

import postEmail from "../../utils/api/email-post";

export default function Form(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState({
    error: false,
    message: "",
    open: true,
  });

  async function handleSubmit(event) {
    event.preventDefault();
    // console.log("First Name:", firstName, "Last Name:", lastName);
    // console.log("Phone:", phone, "Email:", email);
    // console.log("Message:", message);

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setMessage("");

    /* Backend connection to send "Contact us" message */
    if (phone) {
      let infoEmail = { firstName, lastName, phone, email, message };
      postEmail.postEmail(infoEmail);
    } else{
      let infoEmail = { firstName, lastName, email, message };
      postEmail.postEmail(infoEmail);
    }

    /* Show this message if message was sent with success */
    setStatus((prevState) => ({
      ...prevState,
      error: false,
      message: "Thanks for contacting us. We will get back to you shortly",
    }));

    /* Show this error message if message was not sent with success
    setStatus((prevState) => ({
      ...prevState,
      error: true,
      message: "Something went wrong. Try again later",
    }));*/
  }

  function getStatusMessage() {
    let statusMessageElem = <span></span>;
    let statusSeverity = "";

    if (status.message === "") {
      return statusMessageElem;
    }

    if (status.error) {
      statusSeverity = "error";
    } else {
      statusSeverity = "success";
    }

    statusMessageElem = (
      <Collapse in={status.open}>
        <Alert
          severity={statusSeverity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setStatus((prevState) => ({
                  ...prevState,
                  open: false,
                }));
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {status.message}
        </Alert>
      </Collapse>
    );

    return statusMessageElem;
  }

  let statusMessageElem = getStatusMessage();

  return (
    <div className="contacts-form">
      <h2 className="contacts-form-title">Get in touch with us</h2>

      {statusMessageElem}

      <form
        className="contacts-form-body"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="contacts-item">
            <TextField
              id="contacts-first-name"
              label="First Name"
              variant="filled"
              type="text"
              value={firstName}
              onInput={(e) => setFirstName(e.target.value)}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="contacts-item">
            <TextField
              id="contacts-last-name"
              label="Last Name"
              variant="filled"
              type="text"
              value={lastName}
              onInput={(e) => setLastName(e.target.value)}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="contacts-item">
            <TextField
              id="contacts-phone"
              label="Phone"
              variant="filled"
              type="number"
              value={phone}
              onInput={(e) => setPhone(e.target.value)}
              fullWidth
              // required
            />
          </Grid>

          <Grid item xs={12} md={6} className="contacts-item">
            <TextField
              id="contacts-email"
              label="Email"
              variant="filled"
              type="email"
              value={email}
              onInput={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-textarea"
              label="Message"
              variant="filled"
              value={message}
              onInput={(e) => setMessage(e.target.value)}
              multiline
              rows={10}
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <div className="contact-button">
          <FormButton>Send message</FormButton>
        </div>
      </form>
    </div>
  );
}
