import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function YearMonthPicker(props) {
  const getProps = () => {
    const { columnDef, rowData, onRowDataChange, ...dataProps } = props;
    return dataProps;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={props.locale}>
      <DatePicker
        {...getProps()}
        views={["year"]}
        value={props.value || null}
        onChange={props.onChange}
        clearable
        InputProps={{
          style: {
            fontSize: 13,
          },
        }}
        inputProps={{
          "aria-label": `${props.columnDef.title}: press space to edit`,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default YearMonthPicker;