import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: "25px",
    border: "1px solid #FFF",
    color: "#FFFFFF",
    minWidth: 100,
    minHeight: "35px",
    padding: "0 10px",
    margin: "20px",
    "&:hover": {
      border: "none",
      backgroundColor: "#00FFFF",
      opacity: 0.8,
      color: "#000",
    },
    // "@media(max-width: 820px) and (min-width: 705px)" : {
    //   margin: "0px 0px 40px 20px",
    // },
    // "@media(max-width: 704px) and (min-width: 517px)" : {
    //   margin: "20px 40px",
    // }
  },
  selected: {
    border: "none",
    backgroundColor: "#00FFFF",
    opacity: 0.8,
    color: "#000",
  },
  flexContainer: {
    marginBottom: "20px",
    flexWrap: "wrap",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function FilterButton(props) {
  const classes = useStyles();

  let [value, setValue] = React.useState(null);

  if (window.location.pathname === "/whats-new/all") {
    value = 0;
  } else if (window.location.pathname === "/whats-new/news") {
    value = 1;
  } else if (window.location.pathname === "/whats-new/events") {
    value = 2;
  } else if (window.location.pathname === "/whats-new/blog") {
    value = 3;
  }

  //on change sets newValue and replace url param
  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      window.history.pushState({}, `${window.location.pathname}`, "all");
    } else if (newValue === 1) {
      window.history.pushState({}, `${window.location.pathname}`, "news");
    } else if (newValue === 2) {
      window.history.pushState({}, `${window.location.pathname}`, "events");
    } else if (newValue === 3) {
      window.history.pushState({}, `${window.location.pathname}`, "blog");
    }
  };

  return (
    <div>
      <Tabs
        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        value={value}
        onChange={handleChange}
        centered
        classes={{ flexContainer: classes.flexContainer }}
      >
        <Tab
          classes={{ root: classes.root, selected: classes.selected }}
          label="All"
          {...a11yProps(0)}
        />
        <Tab
          classes={{ root: classes.root, selected: classes.selected }}
          label="News"
          {...a11yProps(1)}
        />
        <Tab
          classes={{ root: classes.root, selected: classes.selected }}
          label="Events"
          {...a11yProps(2)}
        />
        <Tab
          classes={{ root: classes.root, selected: classes.selected }}
          label="Blog"
          {...a11yProps(3)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        {props.all}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {props.news}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {props.events}
      </TabPanel>

      <TabPanel value={value} index={3}>
        {props.blog}
      </TabPanel>
    </div>
  );
}
