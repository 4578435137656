import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";
export default class TELLU extends Component {
  render() {
    return (
      <div>
        <MetaTags title = "Tellu | Consortium | DataCloud Project" />
        <PartnersHeader title="Tellu" color="#057ECA"></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              Tellu develops and delivers PaaS and SaaS mainly within eHealth
              and Personell Safety domains. Tellu is an SME experiencing
              significant growth the last few years, going from 5 employees in
              2017 to 18 at the end of 2019. Tellu's main offering is
              TelluCloud, an eHealth and welfare integration platform for
              connectivity, device management, edge computing, data gathering,
              data processing and presentation. The platform offers complete
              management and trustworthy execution and processing across the
              lot, edge and cloud space. In this project a particular focus will
              be to evolve the data management and to also establish trustworthy
              data pipelines across the IoT, edge and cloud infrastructures.
              <br />
              <br />
              As a small technology company, we both provide our platform as
              part of a system of systems with service providers and partners to
              deliver business critical services and we deliver stand- alone
              SaaS to end customers. <br />
              <br />
              Tellu's main markets are currently the eHealth and home care
              market in Norway, and personnel safety market in the Netherlands.
              We also have small installations in other markets including US,
              and the ambition is to significantly growth our international
              business. Tellu has contributed to numerous national and
              international R&D projects, and is currently involved in several
              EU projects within related to the eHalth integration platform and
              exploring technologies such as big data. IoT, and edge and cloud
              computing.
            </div>
            <div className="partner-button">
              <a
                href="https://www.tellucloud.com/"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              Tellu main responsibility will be to develop the eHealth business
              case (TLUHEALTH) and validate project results applying them in the
              use case development and operation. Tellu will work on definition
              and KPI selection for this case, and provide input to the
              technical development. In particular, this will be related to
              explore and improve data pipeline management, data pipeline
              simulation to predict scalability, trustworthiness and performance
              in production conditions and improve trustworthiness of data
              management focusing on both security and privacy concerns.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
