import React, { Component } from "react";

import PageHeader from "../../components/pageheader/PageHeader";
import Filter from "../../components/filter/Filter";
import MetaTags from "../../components/metatags/MetaTags";
import Pagination from "../../templates/pagination/Pagination";

import newsImg from "../../assets/images/header_news_resize.jpg";
import "./News.css";

import getBlogPosts from "../../utils/api/blog-get";
import getEvents from "../../utils/api/events-get";
import getNews from "../../utils/api/news-get";

export default class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      blog: [],
      events: [],
      news: [],
      loading: false
    };
  }

  async componentDidMount() {
    let blogPosts = await getBlogPosts.getBlogPosts();
    let events = await getEvents.getEvents();
    let newsPosts = await getNews.getNews();

    // Merge 3 arrays(blogPosts, events, newsPosts) into one(items)
    let items = [];
    items.push(...blogPosts, ...events, ...newsPosts);

    // Sort array by recent publication date
    let sortedItems = items.sort(function (a, b) {
      var dateA = new Date(a.published),
        dateB = new Date(b.published);
      return dateB - dateA;
    });

    this.setState({
      items: sortedItems,
      blog: blogPosts,
      events: events,
      news: newsPosts,
      loading: true
    });
  }

  render() {
    return (
      <>
        <MetaTags
          title="What's New | DataCloud Project"
        />
        {/* News Page header image and title */}
        <PageHeader img={newsImg} width={740}>
          WHAT'S NEW
        </PageHeader>

        {/* Content Page Wrapper => Defines background and top/bottom padding */}
        <div className="content-page-wrapper">
          {/* Content Page Content => Centered div containing news content */}
          <div className="content-page-content">
            <Filter
              all={<Pagination data={this.state.items} loading={this.state.loading}/>}
              news={<Pagination data={this.state.news} loading={this.state.loading}/>}
              events={<Pagination data={this.state.events} loading={this.state.loading}/>}
              blog={<Pagination data={this.state.blog} loading={this.state.loading}/>}
            ></Filter>
          </div>
        </div>
      </>
    );
  }
}
