import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/MAM4PRO.png";

export default class MOG extends Component {
  render() {
    return (
      <div>
        <MetaTags title = "MOG Technologies | Consortium | DataCloud Project" />
        <PartnersHeader
          title="MOG Technologies"
          color="#48BDEA"
        ></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              MOG TECHNOLOGIES SA is a Portuguese company specialized in
              information technology development for the broadcast industry. MOG
              Technologies SA main activity consists in the development of new
              technological platforms to introduce on the market products,
              systems and solutions which automate the working processes of
              multimedia contents professional operators, mainly video studios,
              television producers, television stations, etc.
              <br />
              <br /> The equipment and services that MOG Technologies has been
              introducing on the market explore the facilities that the new
              technologies allow, automating the content management and
              manipulation since its capture/generation, passing through its
              production and post-production phases until the playout. In its
              systems, MOG handles larges amount of media data and processes it
              in order to make it available in the right format for the end
              user. <br />
              <br />
              MOG Technologies is a Portuguese certified cloud provider,
              delivering CDN and content transmissions systems to different
              broadcast operators.
            </div>
            <div className="partner-button">
              <a
                href="https://www.mog-technologies.com/"
                target="_blank"
                rel="noreferrer"
              >
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              MOG's role in DataCloud will be twofold: it will develop the
              necessary technology for implementing the live annotation of
              sports content business case (MOGSPORTS), in which multicamera
              streams are enriched with relevant metadata related to important
              events detected in the sports event. <br />
              <br />
              The second role will be to lead the Dissemination, Communication
              and Exploitation activities contributing to the project visibility among
              their stakeholders and guaranteeing the project sustainability
              after its lifetime.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
