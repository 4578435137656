import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "black",
    borderRadius: "18px",
    border: "1px solid #00FFFF",
    color: "#00FFFF",
    height: 35,
    padding: "0 30px",
    "&:hover": {
      backgroundColor: "black",
      opacity: 0.8,
    }
  },
  label: {
    textTransform: "uppercase",
    fontSize: "13px",
    fontWeight: 300,
    letterSpacing: "0.65px",
    "@media(max-width: 360px)" : {
      fontSize: "12px",
    }
  },
});

export default function HomeButton(props) {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {props.children}
    </Button>
  );
}