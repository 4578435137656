import React, { Component } from "react";
import styled from "styled-components"
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import "./PartnersHeader.css";

import PartnersButton from "../partnersbutton/PartnersButton";

const Header = styled.div`
  /* background-color is value of props.color */
  background-color: ${(props) => props.color};
`;

export default class PartnersHeader extends Component {
  render() {
    return (
      <Header className="partners-header-section" color={this.props.color}>
        <div className="partners-header-backdrop"></div>
        <div>
          <div className="partner-header-title"> {this.props.title}</div>
          <Link
            variant="inherit"
            color="inherit"
            underline="none"
            component={NavLink}
            to="/consortium/who-we-are"
          >
            <PartnersButton>Back to Who we are</PartnersButton>
          </Link>
        </div>
      </Header>
    );
  }
}
