import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

export default class MetaTags extends Component {
  render() {
    let title = "DataCloud | Enabling the big data pipeline lifecycle on the computing continuum";
    let description = "The DataCloud project envisions the creation of a novel paradigm " +
    "for Big Data pipeline processing encompassing the Computing Continuum.";

    if (this.props.title) { title = this.props.title; }
    if (this.props.description) { description = this.props.description; }

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta 
          name="description" 
          content={description}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
    );
  }
}
