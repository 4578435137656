import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavHashLink as NavLink } from "react-router-hash-link";

import HomeButton from "../../components/homebutton/HomeButton";
import { scrollWithOffset } from "../../utils/utils";

export default class MobileCarouselPanel extends Component {
  render() {
    return (
      <div>
        <div style={{height: "0"}}>-</div>
        <aside className="mobile-panel">
          <div className="panel-img">
            <div className="panel-background">
              <img src={this.props.data.img} alt={this.props.data.header} />
            </div>
          </div>

          <div className="panel-text-container">
            <h2 className="panel-header">{this.props.data.header}</h2>
            <p className="panel-info">{this.props.data.body}</p>
            <Link
              variant="inherit"
              color="inherit"
              underline="none"
              component={NavLink}
              scroll={(el) => scrollWithOffset(el)}
              to={this.props.data.link}
            >
              <HomeButton>{this.props.data.btnText}</HomeButton>
            </Link>
          </div>
        </aside>
      </div>
    );
  }
}
