import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class UKlagenfurt extends Component {
  render() {
    return (
      <div>
        <MetaTags title="University of Klagenfurt | Consortium | DataCloud Project" />
        <PartnersHeader
          title="University of Klagenfurt"
          color="#57A0C7"
        ></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              University of Klagenfurt (AAU) is a young, lively, innovative
              university located in the Austrian state of Carinthia, a meeting
              point of three cultures. AAU is Carinthia's largest academic
              institution and a hub for the whole Alps- Adriatic region in terms
              of knowledge acquisition, exchange and transfer.
              <br />
              <br />
              The Distributed and Parallel Systems group at the Institute of
              Information Technologies led by Prof. Radu Prodan, conducts
              research in the area of distributed computing and systems
              decentralization, including novel approaches for resource
              management and orchestration in Cloud, Fog and Edge,
              multi-criteria workflow scheduling heuristics, performance
              modelling and analysis tools, Cloud storage management and
              federation.
              <br />
              <br />
              As part of a new decentralized social media initiative, led by the
              European Union, the group researches a set of trustworthy,
              resilient, and globally sustainable decentralized social media
              services. The group is currently involved in three European
              Horizon 2020 projects and three other national projects.
            </div>
            <div className="partner-button">
              <a href="https://www.aau.at/en/" target="_blank" rel="noreferrer">
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              AAU is specialized in distributed computing middleware
              technologies. In DataCloud AAU leads activities related to
              adaptable resource provisioning environment for an interoperable
              Cloud-Fog-Edge infrastructure. AAU has also expertise in resource
              discovery, workflow languages and optimization, Cloud workflow
              simulation tools, blockchain-based resource and data federation,
              and optimized software deployment.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
