import { DefaultJournalName, NotApplicable, getTokenJWT } from "../../utils";
import ErrorToast from "../../../components/errortoast/ErrorToast";

const postSP = {

  //Upload image to media library
  async addImage(data, imgData) {
    let imageId;
    let formData = new FormData();
    formData.append("files", imgData);

    var requestOptions = {
      method: "POST",
      headers: {
        "Authorization": getTokenJWT(),
      },
      body: formData,
      redirect: "follow",
    };

    let newData;
    try {
      const response = await fetch(`${window.$apiUrl}/upload`, requestOptions);
      const res = await response.json();

      imageId = res[0]._id;
      newData = await this.addSP(data, imageId).then((result) => {
        return result;
      });
    } catch(error) {
      console.error(" Error adding publication -->", error);
    }

    return newData;
  },

  //Post content to database
  addSP(data, imageId) {
    //Headers
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Authorization", getTokenJWT())

    //Body
    let urlencoded = new URLSearchParams();
    urlencoded.append("rowId", JSON.stringify(data.tableData));
    urlencoded.append("date", data.date);
    urlencoded.append("publicationType", data.publicationType);
    urlencoded.append("authors", data.authors);
    urlencoded.append("publicationTitle", data.publicationTitle);
    urlencoded.append("journalName", data.journalName ? data.journalName : DefaultJournalName);
    urlencoded.append("publicationDOI", data.publicationDOI ? data.publicationDOI : NotApplicable);
    urlencoded.append("zenodoLink", data.zenodoLink);
    urlencoded.append("photo", imageId);

    for (let i = 0; i < data.partners.length; i++) {
      urlencoded.append("partners", data.partners[i]);
    }

    for (let j = 0; j < data.targetAudience.length; j++) {
      urlencoded.append("targetAudience", data.targetAudience[j]);
    }

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(`${window.$apiUrl}/scientific-publications`, requestOptions)
      .then((response) => {return response.json()})
      .then((result) => {
        if (result.statusCode && result.error) {
          console.error(" Error adding publication -->", result);
          ErrorToast(result.error);
          return;
        } else return result;
      })
      .catch((error) => console.error(" Error adding publication -->", error));
  },
};

export default postSP;
