import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import DropdownItem from "./DropdownItem"

import "./Dropdown.css";

export default class ResourcesDropdown extends Component {
  matchResourcesPages = (match, location) => {
    if (location.pathname.startsWith("/resources")) {
      return true;
    }

    return false;
  };

  render() {
    return (
      <div className="dropdown">
        <Link
          className="dropbtn main-link"
          variant="inherit"
          color="inherit"
          underline="none"
          component={NavLink}
          activeClassName="selected"
          isActive={this.matchResourcesPages.bind(this)}
          exact={true}
          to="#"
        >
          Resources
        </Link>

        <div className="dropdown-content">
          <div className="dropdown-placeholder" />

          <div className="dropdown-links">
            <DropdownItem link="/resources/deliverables">
              Deliverables
            </DropdownItem>

            <DropdownItem link="/resources/journal-articles">
              Journal Articles
            </DropdownItem>

            <DropdownItem link="/resources/conference-papers">
              Conference Papers
            </DropdownItem>

            <DropdownItem link="/resources/white-papers">
              White Papers
            </DropdownItem>

            <DropdownItem link="/resources/presentation-slides">
              Presentation Slides
            </DropdownItem>

            <DropdownItem externalLink="https://datacloud-project.github.io/toolbox">
              Software
            </DropdownItem>

            <DropdownItem link="/resources/others">
              Others
            </DropdownItem>
          </div>
        </div>
      </div>
    );
  }
}
