import React, { Component } from "react";
import { Carousel } from "antd";

import CarouselPanel from "./CarouselPanel";
import "./Carousel.css";

export default class CarouselElem extends Component {
  render() {
    return (
      <Carousel
        className="wrapper" 
        dotPosition="left" 
        autoplay={true} 
        vertical={true} 
        draggable={true} 
        verticalSwiping={true}
      >
        {this.props.data.map((value, index) => {
          return <CarouselPanel key={index} data={value} />
        })}
      </Carousel>
    );
  }
};