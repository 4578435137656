import React, { Component } from "react";
import ImageGallery from "react-image-gallery";

import "./Gallery.css";

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showVideo: {},
    };

    this.media = [];
  }

  componentDidMount() {
    this.props.img.map((img) => {
      return this.media.push({
        original: window.$apiUrl + img.url,
        thumbnail: window.$apiUrl + img.formats.thumbnail.url,
        description: img.alternativeText,
      });
    });

    if (this.props.video) {
      //embed youtube video
      const url = new URL(this.props.video);
      const urlParams = new URLSearchParams(url.search);
      const videoID = urlParams.get("v");

      let embedURL = `https://youtube.com/embed/${videoID}`;
      let thumbnailImg = `http://img.youtube.com/vi/${videoID}/0.jpg`;

      this.media.push({
        original: thumbnailImg,
        thumbnail: thumbnailImg,
        embedUrl: embedURL,
        title: "",
        renderItem: this._renderVideo.bind(this),
      });
    }
  }

  _renderVideo(item) {
    return (
      <div>
        <div className="video-wrapper">
          <iframe
            width="560"
            height="315"
            title={item.title}
            src={item.embedUrl}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          {item.description && (
            <span
              className="image-gallery-description"
              style={{ right: "0", left: "initial" }}
            >
              {item.description}
            </span>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <ImageGallery
          items={this.media}
          lazyLoad={false}
          infinite={true}
          showBullets={true}
          showFullscreenButton={
            this.state.showFullscreenButton &&
            this.state.showGalleryFullscreenButton
          }
          showPlayButton={false}
          showThumbnails={true}
          showIndex={false}
          showNav={true}
          isRTL={false}
          thumbnailPosition={"bottom"}
          slideDuration={parseInt(450)}
          slideInterval={parseInt(2000)}
          slideOnThumbnailOver={false}
          additionalClass="app-image-gallery"
          useWindowKeyDown={true}
          className="gallery"
        />
      </div>
    );
  }
}
