import React, { Component } from "react";
import { Grid, Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import MapChart from "./Map";
import ListPartners from "./ListPartners";

import "./MapPartners.css";

export default class MapPartners extends Component {
  constructor(props) {
    super(props);
    this.handleMarkerChange = this.handleMarkerChange.bind(this);
    this.handleImageHover = this.handleImageHover.bind(this);
    this.checkScreenSize = this.checkScreenSize.bind(this);

    this.state = {
      selectedMarker: "",
      selectedImage: "",
      responsiveView: null,
    };
  }

  checkScreenSize() {
    let screenSize = window.innerWidth;

    if (screenSize < 960) {
      this.setState({ responsiveView: true });
    } else {
      this.setState({ responsiveView: false });
    }
  }

  componentDidMount() {
    this.checkScreenSize();

    window.addEventListener("resize", this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  }
  
  handleMarkerChange(selectedPartner) {
    this.setState({
      selectedMarker: selectedPartner,
    });
  }

  handleImageHover(selectedPartner) {
    this.setState({
      selectedImage: selectedPartner,
    });
  }

  render() {
    return (
      <Grid container>
        {!this.state.responsiveView ? (
          <Grid item sm={12} md={6} className="map-wrapper">
            <MapChart
              selectedMarker={this.state.selectedMarker}
              selectedImage={this.state.selectedImage}
              handleMarkerChange={this.handleMarkerChange}
            />
          </Grid>
        ) : null}

        <Grid item sm={12} md={6} className="list-partners-bkg">
          <div className="list-partners-wrap">
            {ListPartners.map((partnerInfo) => {
              let isMarkerSelected =
                this.state.selectedMarker === partnerInfo.name;
              let isImageSelected =
                this.state.selectedImage === partnerInfo.name;

              let isSelected = isMarkerSelected | isImageSelected;

              return (
                <div
                  key={`partner-img-${partnerInfo.name}`}
                  className="list-partners-item"
                >
                  <Link
                    className="list-partners-link"
                    variant="inherit"
                    color="inherit"
                    underline="none"
                    component={NavLink}
                    to={partnerInfo.link}
                  >
                    <img
                      className={isSelected ? "selected-partner" : ""}
                      src={
                        isSelected ? partnerInfo.colorImg : partnerInfo.greyImg
                      }
                      alt={partnerInfo.description}
                      onMouseEnter={() =>
                        this.handleImageHover(partnerInfo.name)
                      }
                      onMouseLeave={() => this.handleImageHover("")}
                    />
                  </Link>
                </div>
              );
            })}

            <div className="list-partners-item phantom-item" />
          </div>
        </Grid>
      </Grid>
    );
  }
}
