import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import PartnersHeader from "../../components/partnersheader/PartnersHeader";
import MetaTags from "../../components/metatags/MetaTags";
import BackButton from "../../components/backbutton/BackButton";
import "./IndividualPartners.css";

import headerImg from "../../assets/images/pj_uc_smark.png";

export default class BOSCH extends Component {
  render() {
    return (
      <div>
        <MetaTags title="BOSCH | Consortium | DataCloud Project" />
        <PartnersHeader title="BOSCH" color="#E20015"></PartnersHeader>

        <Grid container className="partner-about-section">
          <Grid item sm={12} md={6}>
            <img src={headerImg} alt="" className="partner-img"></img>
          </Grid>
          <Grid item sm={12} md={6} className="partner-about-content">
            <div className="partner-about-title">ABOUT</div>
            <div className="partner-about-text">
              Bosch is a manufacturing company with more than 400K associates
              and 270 factories worldwide and it targets a wide range of markets
              including automobile industry, consumer goods, energy, building
              technology. As a leading IoT provider, Bosch offers innovative
              solutions for smart homes, Industry 4.0, and connected mobility.
              <br />
              <br />
              The Bosch Group's strategic objective is to facilitate connected
              living with products and solutions that either contain artificial
              intelligence (AI) or have been developed or manufactured with its
              help. An important technology in the orbit of this objective,
              especially for Bosch's Industrial Technology business sector, are
              effective and efficient pipelines for processing, analyses and AI
              methods over big industrial data. Enhancing this technology is of
              a great importance for Bosch since in 2019 this sector generated
              roughly 10 percent of total Bosch Group sales.
            </div>
            <div className="partner-button">
              <a href="https://www.bosch.com/" target="_blank" rel="noreferrer">
                <BackButton>Visit the website</BackButton>
              </a>
            </div>
          </Grid>
        </Grid>
        <div className="partner-role-section">
          <div className="partner-role-content">
            <div className="partner-role-title">ROLE IN THE PROJECT</div>
            <div className="partner-role-description">
              Bosch will develop the necessary technology for implementing the
              Analytics of Manufacturing Assets business case. In particular,
              Bosch will contribute to the development of market requirements,
              GDPR and ethics compliance, ensuring data disposal and usability
              for business delivery. It will also contribute to business case
              driven resource provisioning and management need via the
              marketplace. Finally, Bosch will contribute to the development of
              the manufacturing business case and validation of DataCloud
              integrated toolbox.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
