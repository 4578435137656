import React, { Component } from "react";

import PageHeader from "../../components/pageheader/PageHeader";
import MetaTags from "../../components/metatags/MetaTags";
import MapPartners from "./MapPartners";

import partnersImg from "../../assets/images/header_partners.png";
import "./Partners.css";

export default class Consortium extends Component {
  render() {
    return (
      <>
        <MetaTags
          title="Who We Are | Consortium | DataCloud Project"
        />
        <PageHeader img={partnersImg} width={770}>
          WHO WE ARE
        </PageHeader>

        <div className="partners-description">
          The Consortium consists of 11 partners from eight countries (Norway,
          Austria, Italy, Sweden, France, Greece, Germany, Spain and Portugal)
          forming a firm pan-European (international) partnership developing
          synergies that strengthen each partner’s individual expertise. The
          consortium consists of a balanced combination of R&D technology
          providers, SMEs, and a large company that ensures advances in the
          state of the art research, high business impact, and agility in the
          delivery of results.
        </div>

        <MapPartners />
      </>
    );
  }
}
