const loginUser = (data) => {
  return fetch(`${window.$apiUrl}/auth/local`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
  .then(response => {
    return response.json();
  })
  .then(jsonResponse => {
    return jsonResponse;
  })
};

export default loginUser;