import React, { Component } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule
} from "react-simple-maps";

import MapMarker from "./MapMarker";
import ListPartners from "./ListPartners";

import GeoData from "../../assets/topojson-maps/world-110m.json";

export default class MapChart extends Component {
  constructor(props) {
    super(props);
    this.handleMarkerChange = this.handleMarkerChange.bind(this);
  }

  handleMarkerChange(selectedPartner) {
    this.props.handleMarkerChange(selectedPartner);
  }

  render() {

    return (
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [10, -60.0, 0],
          scale: 600,
          center: [20, -10]
        }}
        height={500}
        width={700}
      >
        <Graticule fill="#C9C9C9" stroke="#C9C9C9" />
        <Geographies geography={GeoData}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#F5f5f5"
                stroke="#c4c4c4"
                style={{
                  default: { outline: "none" },
                  hover: { outline: "none" },
                  pressed: { outline: "none" },
                }}
              />
            ))
          }
        </Geographies>

        {ListPartners.map((partnerInfo) => {
          return (
            <MapMarker
              key={`partner-marker-${partnerInfo.name}`}
              name={partnerInfo.name}
              coordinates={partnerInfo.coordinates}
              color={partnerInfo.color}
              country={partnerInfo.country}
              selectedMarker={this.props.selectedMarker}
              selectedImage={this.props.selectedImage}
              handleMarkerChange={this.handleMarkerChange}
            />
          );
        })}
      </ComposableMap>
    );
  }

};
