import React, { useState, useEffect } from "react";
import { AppBar } from "@material-ui/core";

import MobileNavBar from "../mobilenavbar/MobileNavbar";
import NavBar from "./Navbar";

export default function Navbar() {
  const [responsiveView, setResponsiveView] = useState(false);

  const checkScreenSize = () => {
    let screenSize = window.innerWidth;

    if (screenSize <= 1125) {
      setResponsiveView(true);
    } else {
      setResponsiveView(false);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // returned function will be called on component unmount 
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    }
  });

  return (
      <AppBar position="sticky" color="secondary" className="navbar">
        {responsiveView ? <MobileNavBar /> : <NavBar />}
      </AppBar>
  );
}
