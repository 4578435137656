import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: "18px",
    border: "1px solid #fff",
    color: "#fff",
    height: 35,
    padding: "0 30px",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    }
  },
  label: {
    textTransform: "capitalize",
    fontSize: "13px",
    fontWeight: 300,
    letterSpacing: "0.65px"
  },
});

export default function PartnersButton(props) {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {props.children}
    </Button>
  );
}